import { REACT_APP_SKIP_PHONE } from 'envs';

import { isPP, isStage } from 'helpers';

export const validURL = (str: string) => {
	if (str.includes(' ')) return false;

	const result = str.startsWith('http://') || str.startsWith('https://');
	if (!result) return false;

	const regex =
		// eslint-disable-next-line no-useless-escape
		/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
	return !!regex.test(str);
};

export const validURLLocal = (str: string) => {
	if (str.includes(' ')) return false;

	const result = str.startsWith('http://') || str.startsWith('https://');
	if (!result) return false;
	const localResult = str.startsWith('http://localhost');
	if (localResult && (isStage || isPP)) return true;
	const regex =
		// eslint-disable-next-line no-useless-escape
		/^(https?:\/\/)?((localhost:\d{1,5})|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))(:\d{1,5})?(\/[-a-zA-Z0-9@:%._\+~#=\/]*)?(\?[;&a-zA-Z0-9%_.~+=-]*)?(#[a-zA-Z0-9-_]*)?$/i;

	// /[(https?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
	return !!regex.test(str);
};

export const getOrigin = () => {
	const ancestorOriginsLength =
		Object.values(document?.location?.ancestorOrigins ?? {})?.length ?? 0;
	const val = window.location === window.parent.location
		? document.location.origin
		: document.location.ancestorOrigins?.[ancestorOriginsLength - 1];

	return val ?? 'https://app.simplici.io'
};

export const getHost = (url: string) => {
	const newUrl = new URL(url);
	return newUrl.host;
};

export const getParentHost = () => {
	const domain = getOrigin();
	const host = getHost(domain as string);
	const hostParts = host?.split('.');
	const mainDomain = hostParts?.[hostParts?.length - 2];
	const domainExtantion = hostParts?.[hostParts.length - 1];
	return mainDomain ? mainDomain + '.' + domainExtantion : domainExtantion;
};

export const getOriginProtocol = () => {
	const domain = getOrigin();
	const newUrl = new URL(domain);
	return newUrl?.protocol ?? 'https:';
};

const convertToBoolean = (value: string | undefined | null | boolean): boolean => {
	return value === 'true';
  };

export const isSkipPhoneEnabled = (): boolean => {
	return  convertToBoolean(REACT_APP_SKIP_PHONE) ;
};
  