export const mobileUINotRenderObject = [
	'publishOnboarding',
	'publishAdditionalSetting',
];
export const pipelineDefaultFormState = {
	allIdDocVerification: true,
	deliveryMethodButton: 'Start Verification',
	deliveryMethodFooter:
		'If you have any questions regarding the verification process, please email us at info@simplici.com',
	deliveryMethodHeadline: 'Hi there',
	deliveryMethodMessage:
		'Please use this link to complete your verification for Simplici.',
	deliveryMethodName: 'Simplici',
	deliveryMethodSubject:
		'Use the provided link to complete your verification for Simplici',
	deliveryMethodTextMessage:
		'Please click on the provided link to complete your verification: {auto generated link will go here}. Thanks!',
	dlDataVerification: true,
	dlDocVerification: true,
	nationDocVerification: true,
	passDocVerification: true,
	publishCredential: '',
	publishName: 'Onboarding flow',
	publishRedirect: 'https://example.com',
	publishWebhook: 'https://example.com',
	successScreenDocTitle: 'Yay!',
	successScreenMessage: 'Verification Complete',
};

export const supportedCountries = [
	{
		label: 'United States',
		value: 'US',
	},
	{
		label: 'United Kingdom',
		value: 'GB',
	},
	{
		label: 'Spain',
		value: 'ES',
	},
	{
		label: 'Netherlands',
		value: 'NL',
	},
	{
		label: 'France',
		value: 'FR',
	},
	{
		label: 'Ireland',
		value: 'IE',
	},
	{
		label: 'Canada',
		value: 'CA',
	},
	{
		label: 'Germany',
		value: 'DE',
	},
	{
		label: 'Italy',
		value: 'IT',
	},
];

export const supportedLang = [
	{
		label: 'English',
		value: 'en',
	},
	{
		label: 'French',
		value: 'fr',
	},
	{
		label: 'Spanish',
		value: 'es',
	},
	{
		label: 'Dutch',
		value: 'nl',
	},
	{
		label: 'German',
		value: 'de',
	},
];

export const accreditationType = [
	{
		label: 'Self Accreditation (506b)',
		value: '506b',
	},
	{
		label: 'Accreditation (506c)',
		value: '506c',
	},
];

export const defaultAccreditationType = {
	label: 'Self Accreditation (506b)',
	value: '506b',
};

export const defaultLang = {
	label: 'English',
	value: 'en',
};

export const defaultCountry = {
	label: 'United States',
	value: 'US',
};

export const onboardingPublishMessage = {
	WARNING_PUBLISH:
		'Note: All verification and authentication service fees will be charged for each person completed. Rejection of the verification does not negate the fee for the service completed.',
	WARNING_TEXT_MESSGAE:
		'Note: The {auto generated link will go here} in the message content will be replaced with a link that is unique for the user.',
	PRICE_BREAKDOWN_LABEL: 'Price Breakdown',
	PRICE_BREAKDOWN_DESCRIPTION:
		'The total cost incurred for each verification has been broken down into its separate action with its price.',
};

export const COST_CALCULATOR = {
	TITLE: 'Cost Estimate Calculator',
	NUM_OF_VARIFICATION: ' Number of Verifications',
	CALCULATE_ESTIMATED:
		'Enter the estimated number of invitations/customers for this onboarding flow.',
	EXTIMATED_COST: '  Estimated Cost',
};

export const NavigateStepper = {
	FlowBilder: 'flowBuilder',
	ComplexFlow: 'complex-flow',
};

export const PublishErrorMessage: any = {
	publishName: 'Onboarding Flow Name is required',
	publishRedirect: 'Invalid url address',
	publishWebhook: 'Invalid url address',
	kybMatches: 'Invalid value (Accepts value from 1 to 10)',
	kybMatchThreshold:
		'Invalid value (Accepts value from 0 to 1, Like 0.1, 0.4, 0.9, ....)',
};
