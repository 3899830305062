import React, { FC, useCallback, useMemo, useState } from 'react';
import ReactFlow, {
	Controls,
	MiniMap,
	Handle,
	Position,
	addEdge,
	Edge,
	Connection,
	BezierEdge,
	EdgeProps,
    ReactFlowInstance,
} from 'reactflow';
import dagre from 'dagre';
import 'reactflow/dist/style.css';
import './tree-graph-chart.scss';

interface NodeColor {
	id: string;
	color: string;
	borderColor: string;
}
type ChartData = [string, string, string][];
interface CustomNodeProps {
	data: {
		key:string,
		label: string;
		color: string;
		id: string;
	};
}

interface ChartProps {
	isSessionModal: boolean;
	isInvite: boolean;
	isStepper: boolean;
	onClick: (id: string) => void;
	nodeColor: NodeColor[];
	chartData: ChartData;
	details: any;
	overlayTemplateIds:string[];
}

export const ReactFlowCustomChart: FC<ChartProps> = ({
	isSessionModal,
	isInvite,
	isStepper,
	onClick,
	nodeColor,
	chartData,
	details,
	overlayTemplateIds
}) => {
 
	const CustomNode = useCallback(({ data }: CustomNodeProps) => {
		const truncateLabel = (label: string) => {
			if (!label || typeof label !== 'string') return '';
			return label.length <= 10
				? label
				: label.length == 14
				? label
				: `${label.slice(0, 14).trim()}...`;
		};
		return (
			<div
				style={{ position: 'relative', display: 'inline-block' }}
				className="node_tooltip_hover"
			>
				{/* configure This */}
				<div
					style={{
						...((isStepper && data?.color === '#FFFFFF'
							? {
									visibility: 'visible',
									backgroundColor: 'blue',
									color: 'white',
									bottom: '110%',
									left: '50%',
							  }
							: {
									visibility: 'hidden',
							  }) as React.CSSProperties),
						transition: 'opacity 0.2s ease-in-out',
						textAlign: 'center',
						padding: '5px 10px',
						borderRadius: '4px',
						boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.53)',
						fontSize: '10px',
						position: 'absolute',
						transform: 'translateX(-50%)',
						whiteSpace: 'nowrap',
						cursor: 'pointer',
					}}
				>
					Configuring this
				</div>
				{/* onClick */}
				<div
					style={{
						...((isInvite &&
						(data.color === '#8c97b8' || data.color === '#33b87a') &&
						(data.key === 'signAgreementVerification' ||
							data.key === 'proofVerification' ||
							data.key === 'fundInvestmentVerification')
							? {
									visibility: 'visible',
									backgroundColor: 'blue',
									color: 'white',
									bottom: '110%',
									left: '50%',
							  }
							: {
									visibility: 'hidden',
							  }) as React.CSSProperties),
						transition: 'opacity 0.2s ease-in-out',
						textAlign: 'center',
						padding: '5px 10px',
						borderRadius: '4px',
						boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.53)',
						fontSize: '10px',
						position: 'absolute',
						zIndex: 10,
						transform: 'translateX(-50%)',
						whiteSpace: 'nowrap',
						cursor: 'pointer',
					}}
					onClick={() => onClick(data.id)}
				>
					{overlayTemplateIds?.includes(data.id) &&
					data.key === 'signAgreementVerification' &&
					isInvite
						? 'Recipients Details'
						: isInvite &&
						  data.color === '#8c97b8' &&
						  (data.key === 'signAgreementVerification' ||
								data.key === 'proofVerification')
						? 'Upload'
						: isInvite &&
						  data.color === '#33b87a' &&
						  (data.key === 'signAgreementVerification' ||
								data.key === 'proofVerification')
						? 'Re Upload'
						: isInvite &&
						  data.color === '#8c97b8' &&
						  data.key === 'fundInvestmentVerification'
						? 'Configure'
						: data.label}
				</div>
				{/* tooltip */}
				<div
					className="node_tooltip"
					style={{
						...((isInvite &&
						(data.color === '#8c97b8' || data.color === '#33b87a') &&
						(data.key === 'signAgreementVerification' ||
							data.key === 'proofVerification' ||
							data.key === 'fundInvestmentVerification')
							? {
									bottom: '-80%',
							  }
							: {
									bottom: '100%',
							  }) as React.CSSProperties),
					}}
				>
					{data.label}
				</div>
				{/* BorderDiv mainDiv */}
				<div
					style={{
						...((isStepper && data?.color === '#FFFFFF') ||
						(isInvite && !overlayTemplateIds?.includes(data.id)&&
							data.color === '#8c97b8' &&
							(data.key === 'signAgreementVerification' ||
								data.key === 'proofVerification' ||
								data.key === 'fundInvestmentVerification'))
							? {
									position: 'relative',
									width: data.key === 'authentication' ? '71px' : '107px',
									height: `${30 + 2}px`,
									background: 'blue',
									clipPath:
										'polygon(93% 0%, 100% 50%, 93% 100%, 0% 100%, 7px 50%, 0% 0%)',
							  }
							: {
									width: data.key === 'authentication' ? '69px' : '105px',
									height: `${30}px`,
							  }),
					}}
				>
					{/* MainDiv */}
					<div
						style={{
							width: data.key === 'authentication' ? '69px' : '105px',
							height: `${30}px`,
							background:
								isInvite &&
								data.color === '#8c97b8' && !overlayTemplateIds?.includes(data.id) &&
								(data.key === 'signAgreementVerification' ||
									data.key === 'proofVerification' ||
									data.key === 'fundInvestmentVerification')
									? '#FFFFFF'
									: data?.color || '#8c97b8',
							clipPath:
								'polygon(93% 0%, 100% 50%, 93% 100%, 0% 100%, 7px 50%, 0% 0%)',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							position: 'absolute',
							...(isStepper || isInvite
								? { top: `${1}px`, left: `${1}px` }
								: {}),
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						onClick={() => onClick(data.id)}
					>
						{/* label */}
						<div
							style={{
								fontSize: '10px',
								...((isStepper && data?.color === '#FFFFFF') ||
								(isInvite && !overlayTemplateIds?.includes(data.id) &&
									data.color === '#8c97b8' &&
									(data.key === 'signAgreementVerification' ||
										data.key === 'proofVerification' ||
										data.key === 'fundInvestmentVerification'))
									? { color: 'blue' }
									: { color: 'white' }),
								position: 'absolute',
								left: 9,
								right: 10,
							}}
						>
							{truncateLabel(data.label)}
						</div>
					</div>
				</div>

				<Handle type="source" position={Position.Right} id="right" />
				<Handle type="source" position={Position.Top} id="top" />
				<Handle type="source" position={Position.Bottom} id="bottom" />
				<Handle type="target" position={Position.Left} id="left" />
			</div>
		);
	}, [isInvite, isStepper, onClick, overlayTemplateIds]);

	const CustomEdge: FC<EdgeProps> = useCallback((props) => (
		<BezierEdge
		  {...props}
		  style={{ stroke: 'gray', strokeWidth: 1 }}
		  markerEnd="url(#arrowhead)"
		/>
	  ), []);
	  

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const nodeTypes = useMemo(() => ({ custom: CustomNode }), []);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const edgeTypes = useMemo(() => ({ custom: CustomEdge }), []);
	

	const getColorById = useCallback(
		(id: string) => nodeColor.find(item => item?.id === id)?.color,
		[nodeColor]
	);

	const getInitialNodes = useCallback(
		(node: any) => {
			return (
				isSessionModal || isInvite || isStepper ? node : node.slice(0, 10)
			).map((item: any) => ({
				id: item.id,
				type: 'custom',
				data: { label: item.label, color: getColorById(item.id), id: item.id,key:item.key },
			}));
		},
		[isSessionModal, isInvite, isStepper, getColorById]
	);

	const initialNodes = useMemo(() => getInitialNodes(details), [
		details,
		getInitialNodes,
	  ]);

	const initialEdges: Edge[] = useMemo(() => {
		const edges =
			(isSessionModal || isInvite || isStepper)&& initialNodes.length>1  ? chartData : chartData.slice(1);
		return edges.map((edge, index: number) => ({
			id: `e${index + 1}`,
			source: edge[0],
			target: edge[1],
			animated: true,
			type: 'custom',
		}));
	}, [isSessionModal, isInvite, isStepper, initialNodes.length, chartData]);

	const isHaveChildNodes = useCallback((initialEdges: Edge[]) => {
		const sourceCount = initialEdges.reduce((acc:  { [key: string]: number }, edge: Edge) => {
			acc[edge.source] = (acc[edge.source] || 0) + 1;
			return acc;
		}, {});
		return Object.values(sourceCount).some((count) => count > 1);
	}, []);

	const getLayoutedElements = useCallback(
		(nodes: any, edges: Edge[], direction = 'LR') => {
			const dagreGraph = new dagre.graphlib.Graph();
			dagreGraph.setDefaultEdgeLabel(() => ({}));
			dagreGraph.setGraph({ rankdir: direction });


			nodes.forEach((node: any) => {
				const nodeWidth = 74; 
				dagreGraph.setNode(node.id, { width: nodeWidth, height: 55 });
			})
			
			edges.forEach((edge: Edge) => {
				dagreGraph.setEdge(edge.source, edge.target);
			});

			dagre.layout(dagreGraph);

			nodes.forEach((node: any) => {
				const nodeWithPosition = dagreGraph.node(node.id);
				node.position = {
					x: nodeWithPosition.x - 50,
					y: nodeWithPosition.y - 20,
				};
				node.draggable = false;
			});

			return { nodes, edges };
		},
		[]
	);
	const layoutedElements = useMemo(() => {
		return getLayoutedElements(initialNodes, initialEdges);
	  }, [initialNodes, initialEdges, getLayoutedElements]);
	  
	  const { edges: layoutedEdges } = layoutedElements;
	  
	const [edges, setEdges] = useState(layoutedEdges);

	const onConnect = useCallback(
		(params: Edge | Connection) =>
			setEdges((eds: Edge[]) => addEdge(params, eds)),
		[]
	);

	const onInit = useCallback(
		(instance: ReactFlowInstance) => {
			if (isSessionModal || isInvite) {
				instance.fitView();
			} else {
				instance.setViewport(
					isStepper ? { x: 50, y: 50, zoom: 1 } : { x: 100, y: 200, zoom: 1 }
				);
			}
		},
		[isSessionModal, isInvite, isStepper]
	);
	

	return (
		<ReactFlow
			nodes={initialNodes}
			edges={edges}
			onConnect={onConnect}
			nodeTypes={nodeTypes}
			edgeTypes={edgeTypes}
			fitView
			onInit={onInit}
			zoomOnScroll={false}
			zoomOnPinch={false}
			zoomOnDoubleClick={false}
			panOnDrag={true}
			nodesDraggable={false}
			panOnScroll={true}
			className={
				isSessionModal || isInvite || isStepper
					? 'modal_is_open'
					: isHaveChildNodes(initialEdges)
					? 'havechild_node_chart'
					: 'nochild_node_chart'
			}
		>
			<svg>
				<defs>
					<marker
						id="arrowhead"
						markerWidth="15"
						markerHeight="20"
						refX="10"
						refY="10"
						orient="auto"
					>
						<polygon
							points="10 0, 15 10, 10 20, 8 20, 12 10, 8 0"
							fill="gray"
						/>
					</marker>
				</defs>
			</svg>
			{isSessionModal || isInvite ? (
				<>
					<Controls />
					<MiniMap />
				</>
			) : (
				<></>
			)}
		</ReactFlow>
	);
};
