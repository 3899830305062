export const ROUTES = {
	SESSIONS: '/sessions',
	USER_ROLES: '/user-roles',
	PIPELINE: '/onboarding',
	SIGNUP: '/signup',
	LOGIN: '/login',
	SETTINGS: '/settings',
	CREDENTIALS: '/credentials',
	INVITES: '/invites',
	MINTCARTA: '/mint-carta',
	ON_BOARD: '/checkout',
	FLOW: '/flow',
	NO_ROUTE: '/',
	FUNDS: '/funds',
	FUNDSDETAILS: '/funds/:id',
	SIGNED_DOC: '/esign-templates',
	BILLING: '/billing',
	DASHBOARD: '/dashboard',
	INTEGRATE: '/integrate',
	Otp_Verify: '/otp-verify',
	CHOOSE_METHOD: '/choose-method',
	TRANSACTIONS: '/transactions',
	STAKEHOLDER: '/stakeholder',
	SHARECLASS: '/shareclass',
	EQUITYPLAN: '/equidityplan',
	CAPTABLE: 'captable',
	SCAN_QRCODE: '/scan_qrcode',
	QRCODE_FACE_DEMO: '/qrcode_face_demo',
	UPLODAD_DOCUMENTS: '/files-documents/:id',
	FACE_VERIFY_QR_CODE: '/face_verify_qrcode',
	RECIPIENT_FACE_VERIFY: '/face_verfify_recipient',
	API: '/api',
	ANALYTICS: '/analytics',
	ESIGN: '/esign',
	SUPPORT_AGENT: '/support-agent',
	SIGNED_DOC_INBOX: '/esign-sent',
	COMPLETED: '/completed',
	INBOX: '/esign-inbox',
	SIMPLICI_SIGN_DASHBOARD: '/esign-dashboard',
	SUB_ACCOUNT: '/sub-account',
	CHEQUE_FRAUD: '/check-defense',
	BUSINESS_PROCESSING: '/business-processing',
	BUSINESS_REJECTED: '/business-rejected',
	DEALS: '/deals',
	AUTH: '/auth',
	EVENTS: '/events',
	ESIGN_DRAFT: '/esign-draft',
};

export const ACTIVE_TABS = {
	PIPELINE: 'onboarding',
	CREDENTIALS: 'credentials',
	SESSIONS: 'sessions',
	SETTINGS: 'settings',
	INVITES: 'invites',
	MINTCARTA: 'mint-carta',
	USER_ROLES: 'user-roles',
	FUNDS: 'funds',
	SIGNED_DOC_TEMPLATES: 'esign-templates',
	SIGNED_DOC_INBOX: 'esign-sent',
	BILLING: 'billing',
	INTEGRATE: 'integrate',
	TRANSACTIONS: 'transactions',
	CHEQUE_FRAUD: 'check-defense',
	STAKEHOLDER: 'stakeholder',
	SHARECLASS: 'shareclass',
	EQUITYPLAN: 'equidityplan',
	DASHBOARD: 'dashboard',
	API: 'api',
	ANALYTICS: 'analytics',
	SIMPLICI_SIGN: 'simplici-sign',
	SUPPORT_AGENT: 'support-agent',
	SIMPLICI_SIGN_DASHBOARD: 'esign-dashboard',
	INBOX: 'esign-inbox',
	SUB_ACCOUNT: 'sub-account',
	DEALS: 'deals',
	AUTH: 'auth',
	EVENTS: 'events',
	DRAFT: 'esign-draft'
};

export const SIDEBAR_TABS = {
	label: 'Main Menu',
	tabs: [
		{
			ROUTE: ACTIVE_TABS.DASHBOARD,
			TITLE: 'Dashboard',
			ICON: 'ri-dashboard-',
			TAB_INDEX: 13,
		},
		{
			ROUTE: ACTIVE_TABS.PIPELINE,
			TITLE: 'Onboarding',
			ICON: 'ri-archive-stack-',
			TAB_INDEX: 1,
			SUB_ROUTE: [
				{
					ROUTE: ACTIVE_TABS.PIPELINE,
					TITLE: 'Onboarding Flow',
					ICON: 'ri-list-check-2',
				},
				{
					ROUTE: ACTIVE_TABS.SESSIONS,
					TITLE: 'Sessions',
					ICON: 'ri-contacts-',
				},
				{
					ROUTE: ACTIVE_TABS.FUNDS,
					TITLE: 'Funds',
					ICON: 'ri-funds-',
				},
				{
					ROUTE: ACTIVE_TABS.EVENTS,
					TITLE: 'Events',
					ICON: 'ri-calendar-event-line',
				},
			],
		},
		{
			ROUTE: ACTIVE_TABS.SIMPLICI_SIGN,
			TITLE: 'esign',
			ICON: 'ri-draft-',
			TAB_INDEX: 15,
			SUB_ROUTE: [
				{
					ROUTE: ACTIVE_TABS.SIMPLICI_SIGN_DASHBOARD,
					TITLE: 'esign Dashboard',
					ICON: 'ri-draft-',
				},
				{
					ROUTE: ACTIVE_TABS.SIGNED_DOC_TEMPLATES,
					TITLE: 'esign Templates',
					ICON: 'ri-article-',
				},
				{
					ROUTE: ACTIVE_TABS.DRAFT,
					TITLE: 'Draft',
					ICON: 'ri-mail-send-',
				},
				{
					ROUTE: ACTIVE_TABS.INBOX,
					TITLE: 'Inbox',
					ICON: 'ri-inbox-',
				},
				{
					ROUTE: ACTIVE_TABS.SIGNED_DOC_INBOX,
					TITLE: 'Sent',
					ICON: 'ri-mail-send-',
				},
			],
		},
		{
			ROUTE: ACTIVE_TABS.TRANSACTIONS,
			TITLE: 'Finance',
			ICON: 'ri-hand-coin-',
			TAB_INDEX: 2,
			SUB_ROUTE: [
				{
					ROUTE: ACTIVE_TABS.TRANSACTIONS,
					TITLE: 'Transactions',
					ICON: 'ri-exchange-dollar-',
				},
				{
					ROUTE: ACTIVE_TABS.CHEQUE_FRAUD,
					TITLE: 'Check Defense',
					ICON: 'ri-shield-check-',
				},
			],
		},
		{
			ROUTE: ACTIVE_TABS.DEALS,
			TITLE: 'Escrow',
			ICON: 'ri-shake-hands-',
			TAB_INDEX: 22,
			SUB_ROUTE: [
				{
					ROUTE: ACTIVE_TABS.DEALS,
					TITLE: 'Deals',
					ICON: 'ri-file-lock-',
				},
			],
		},
		{
			ROUTE: ACTIVE_TABS.AUTH,
			TITLE: 'Auth',
			ICON: 'ri-bank-card-',
			TAB_INDEX: 21,
		},
		{
			ROUTE: ACTIVE_TABS.SETTINGS,
			TITLE: 'Settings',
			ICON: 'ri-settings-5-',
			TAB_INDEX: 3,
			SUB_ROUTE: [
				{
					ROUTE: ACTIVE_TABS.SETTINGS,
					TITLE: 'General Settings',
					ICON: 'ri-settings-5-',
				},
				{
					ROUTE: ACTIVE_TABS.CREDENTIALS,
					TITLE: 'Developers Keys',
					ICON: 'ri-profile-',
				},
				{
					ROUTE: ACTIVE_TABS.USER_ROLES,
					TITLE: 'User Roles',
					ICON: 'ri-team-',
				},
				{
					ROUTE: ACTIVE_TABS.SUB_ACCOUNT,
					TITLE: 'Sub Accounts',
					ICON: 'ri-briefcase-2-',
				},
			],
		},

		{
			ROUTE: ACTIVE_TABS.INTEGRATE,
			TITLE: 'Integrate',
			ICON: 'ri-guide-',
			TAB_INDEX: 8,
			SUB_ROUTE: [
				{
					ROUTE: ACTIVE_TABS.INTEGRATE,
					TITLE: 'Embed Web',
					ICON: 'ri-settings-5-',
				},
				{
					ROUTE: ACTIVE_TABS.API,
					TITLE: 'API Docs',
					ICON: 'ri-settings-5-',
				},
			],
		},
		{
			ROUTE: ACTIVE_TABS.ANALYTICS,
			TITLE: 'Insights',
			ICON: 'ri-bar-chart-2-',
			TAB_INDEX: 14,
			SUB_ROUTE: [
				{
					ROUTE: ACTIVE_TABS.ANALYTICS,
					TITLE: 'User Analytics',
					ICON: 'ri-bar-chart-2-',
				},
				{
					ROUTE: ACTIVE_TABS.SUPPORT_AGENT,
					TITLE: 'Support Agent',
					ICON: 'ri-user-star-',
				},
			],
		},

		{
			ROUTE: ACTIVE_TABS.BILLING,
			TITLE: 'Billing',
			ICON: 'ri-bank-card-',
			TAB_INDEX: 9,
		},
	],
};

export const SIDEBAR_COLLAPSE_TABS = {
	label: 'Main Menu',
	tabs: [
		{
			ROUTE: ACTIVE_TABS.DASHBOARD,
			TITLE: 'Dashboard',
			ICON: 'ri-dashboard-',
			TAB_INDEX: 13,
			SUB_ROUTE: [],
		},
		{
			ROUTE: ACTIVE_TABS.PIPELINE,
			TITLE: 'Onboarding',
			ICON: 'ri-archive-stack-',
			TAB_INDEX: 1,
		},
		{
			ROUTE: ACTIVE_TABS.SESSIONS,
			TITLE: 'Sessions',
			ICON: 'ri-contacts-',
			TAB_INDEX: 6,
		},
		{
			ROUTE: ACTIVE_TABS.FUNDS,
			TITLE: 'Funds',
			ICON: 'ri-funds-',
			TAB_INDEX: 2,
		},
		{
			ROUTE: ACTIVE_TABS.EVENTS,
			TITLE: 'Events',
			ICON: 'ri-calendar-event-line',
			TAB_INDEX: 15,
		},
		{
			ROUTE: ACTIVE_TABS.SIMPLICI_SIGN_DASHBOARD,
			TITLE: 'esign Dashboard',
			ICON: 'ri-draft-',
			TAB_INDEX: 3,
		},
		{
			ROUTE: ACTIVE_TABS.SIGNED_DOC_TEMPLATES,
			TITLE: 'esign Templates',
			ICON: 'ri-article-',
			TAB_INDEX: 18,
		},
		{
			ROUTE: ACTIVE_TABS.DRAFT,
			TITLE: 'Draft',
			ICON: 'ri-mail-send-',
			TAB_INDEX: 99,
		},
		{
			ROUTE: ACTIVE_TABS.INBOX,
			TITLE: 'Inbox',
			ICON: 'ri-inbox-',
			TAB_INDEX: 99,
		},
		{
			ROUTE: ACTIVE_TABS.SIGNED_DOC_INBOX,
			TITLE: 'Sent',
			ICON: 'ri-mail-send-',
			TAB_INDEX: 49,
		},

		{
			ROUTE: ACTIVE_TABS.TRANSACTIONS,
			TITLE: 'Transactions',
			ICON: 'ri-exchange-dollar-',
			TAB_INDEX: 8,
		},
		{
			ROUTE: ACTIVE_TABS.CHEQUE_FRAUD,
			TITLE: 'Check Defense',
			ICON: 'ri-shield-check-',
			TAB_INDEX: 98,
		},
		{
			ROUTE: ACTIVE_TABS.DEALS,
			TITLE: 'Deals',
			ICON: 'ri-shake-hands-',
			TAB_INDEX: 100,
		},
		{
			ROUTE: ACTIVE_TABS.SETTINGS,
			TITLE: 'Settings',
			ICON: 'ri-settings-5-',
			TAB_INDEX: 12,
		},
		{
			ROUTE: ACTIVE_TABS.CREDENTIALS,
			TITLE: 'Developer Keys',
			ICON: 'ri-profile-',
			TAB_INDEX: 4,
		},
		{
			ROUTE: ACTIVE_TABS.USER_ROLES,
			TITLE: 'User Roles',
			ICON: 'ri-team-',
			TAB_INDEX: 7,
		},
		{
			ROUTE: ACTIVE_TABS.SUB_ACCOUNT,
			TITLE: 'Sub Account',
			ICON: 'ri-briefcase-2-',
			TAB_INDEX: 19,
		},
		{
			ROUTE: ACTIVE_TABS.INTEGRATE,
			TITLE: 'Integrate',
			ICON: 'ri-guide-',
			TAB_INDEX: 9,
		},
		{
			ROUTE: ACTIVE_TABS.API,
			TITLE: 'Api Docs',
			ICON: 'ri-pages-',
			TAB_INDEX: 10,
		},
		{
			ROUTE: ACTIVE_TABS.ANALYTICS,
			TITLE: 'User Analytics',
			ICON: 'ri-bar-chart-2-',
			TAB_INDEX: 15,
		},
		{
			ROUTE: ACTIVE_TABS.SUPPORT_AGENT,
			TITLE: 'Support Agent',
			ICON: 'ri-user-star-',
			TAB_INDEX: 16,
		},
		{
			ROUTE: ACTIVE_TABS.BILLING,
			TITLE: 'Billing',
			ICON: 'ri-bank-card-',
			TAB_INDEX: 11,
		},
	],
};

export const SIDEBAR_ACCOUNT_TABS = {
	label: 'Account',
	tabs: [
		{
			TITLE: 'Request Demo',
			ICON: 'ri-customer-service-2-line',
			TAB_INDEX: 4,
			isWebcomponentShow: false,
		},
		{
			TITLE: 'User Profile',
			ICON: 'ri-user-line',
			TAB_INDEX: 3,
			isWebcomponentShow: false,
		},
		{
			TITLE: 'Logout',
			ICON: 'ri-logout-circle-line',
			TAB_INDEX: 5,
			isWebcomponentShow: true,
		},
	],
};

export const SUB_SIDEBAR: Record<string, string> = {
	onboarding: 'Onboarding Flow',
	session: 'Sessions',
	funds: 'Funds',
	signDashboard: 'esign Dashboard',
	template: 'esign Templates',
	inbox: 'Inbox',
	sent: 'Sent',
	transaction: 'Transactions',
	generalSettings: 'General Settings',
	developersKeys: 'Developers Keys',
	userRoles: 'User Roles',
	embedWeb: 'Embed Web',
	apiDocs: 'API Docs',
	// userAnalytics: 'User Analytics',
	// supportAgent: 'Support Agent',
	checkDefense: 'Check Defense',
	deals: 'Deals',
	subAccount: 'Sub Accounts',
	events: 'Events',
	draft: 'Draft',
};

export const PARENT_SIDEBAR: Record<string, string> = {
	dashboard: 'Dashboard',
	onboarding: 'Onboarding',
	session: 'Onboarding',
	funds: 'Onboarding',
	signDashboard: 'esign',
	template: 'esign',
	inbox: 'esign',
	sent: 'esign',
	transaction: 'Finance',
	generalSettings: 'Settings',
	developersKeys: 'Settings',
	userRoles: 'Settings',
	embedWeb: 'Integrate',
	apiDocs: 'Integrate',
	// userAnalytics: 'Insights',
	// supportAgent: 'Insights',
	billing: 'Billing',
	checkDefense: 'Finance',
	deals: 'Escrow',
	subAccount: 'Sub Account',
	auth: 'Auth',
	events: 'Onboarding',
	draft: 'esign',
};

export const MOBILE_VIEW_TABS: Record<string, string> = {
	dashboard: 'Dashboard',
	onboarding: 'Onboarding',
	session: 'Sessions',
	funds: 'Funds',
	signDashboard: 'esign Dashboard',
	template: 'esign Templates',
	inbox: 'Inbox',
	sent: 'Sent',
	transaction: 'Transactions',
	generalSettings: 'Settings',
	developersKeys: 'Developers Keys',
	userRoles: 'User Roles',
	embedWeb: 'Integrate',
	apiDocs: 'API Docs',
	// userAnalytics: 'User Analytics',
	// supportAgent: 'Support Agent',
	billing: 'Billing',
	checkDefense: 'Check Defense',
	subAccount: 'Sub Account',
	deals: 'Deals',
	auth: 'Auth',
	events: 'Events',
	darft: 'Draft',
};
