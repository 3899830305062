import { Image } from '@storybook';
import { useRecoilState } from 'recoil';
import { EventsState, IEventsState, IEventUser } from 'views/events/store';
import { EventCard } from '../event-card';
import QRCode from 'react-qr-code';

import Modal from '@storybook/new-modal/modal';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { EventUserCard } from '../event-user-card';
import { ViewSessionRoprt } from '../view-session';
import { EVENTURL } from 'views/events/constants';
import { useNotification } from 'hooks';
import { UserRoleSkeletonLoader } from 'views/user-roles/components/user-role-loader';

import './events-list.scss';

interface IEventsList {
	apiLoading: boolean;
}

export const EventsList: FC<IEventsList> = ({ apiLoading }) => {
	const [eventsState, setEventsState] = useRecoilState(EventsState);
	const [isShowModalUser, setShowModalUser] = useState(false);
	const [isShowQRModal, setShowQRModal] = useState(false);

	const [selectedEventId, setEventId] = useState('');
	const [renderType, setRenderType] = useState('');
	const { successNotification, errorNotification } = useNotification();

	const [eventUsers, setEventUsers] = useState<IEventUser | null>(null);

	const handleClose = useCallback(() => {
		setShowModalUser(false);
		setShowQRModal(false);
	}, []);

	const handleBackClose = useCallback(() => {
		setRenderType('');
	}, []);

	const users = useMemo(
		() =>
			eventsState?.find(event => event._id === selectedEventId)?.users ?? [],
		[eventsState, selectedEventId]
	);

	const event: IEventsState = useMemo(
		() =>
			eventsState?.find(event => event._id === selectedEventId) as IEventsState,
		[eventsState, selectedEventId]
	);

	const qrIds = useMemo(
		() =>
			eventsState?.find(event => event._id === selectedEventId)?.qrIds ?? [],
		[eventsState, selectedEventId]
	);

	const handleOpen = useCallback((selectedEventId: string) => {
		setEventId(selectedEventId);
		setShowModalUser(true);
	}, []);

	const onViewSession = (users: IEventUser) => () => {
		setEventUsers(users);
		setRenderType('ViewSession');
	};

	const onStatusChange = async (
		qrIds: string[],
		id: string,
		type: 'REJECTED' | 'APPROVED'
	) => {
		try {
			const usersData = structuredClone(users);
			const updatedUser = usersData.find(u => u._id === id);

			if (updatedUser) {
				updatedUser['status'] = type;
				updatedUser['changedAt'] = new Date().toISOString();
			}
			const payload = {
				users: usersData,
			};

			const resp = await fetch(`${EVENTURL}/events/${qrIds[0]}`, {
				method: 'PATCH',
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json',
				},
			});

			const data = await resp.json();
			if (data?.message === 'ok') {
				const { _id } = data.data ?? {};
				successNotification('Approved successfully');
				setEventsState(prev => {
					const prevObj = structuredClone(prev);
					const fondUser = prevObj.find(el => el._id === _id);
					if (fondUser) {
						fondUser.users = usersData;
					}
					return prevObj;
				});
			}
		} catch (error) {
			errorNotification('Failed to approve. Please try again.');
		}
	};

	const openQrModal = (e: MouseEvent<HTMLElement>, id: string) => {
		e.stopPropagation();
		setEventId(id);
		setShowQRModal(true);
	};

	const renderViewBody = () => {
		if (!users.length) {
			return (
				<>
					<div className="Events--header">
						<h2>Users</h2>
					</div>
					<div className="user-sumary--empty-state EventsList--empty-body">
						<Image
							fileName="billing-detail-illustration.svg"
							className="Billing-detail-empty-image"
						/>
						<h4>No User Available</h4>
					</div>
				</>
			);
		}

		if (renderType === 'ViewSession') {
			return (
				<div className="EventsList--modal-main">
					<div className="Events--header-back" onClick={handleBackClose}>
						<i className="ri-arrow-left-line"></i>
						<span>Back</span>
					</div>
					<div className="EventsList--Users EventsList--Users-Modal-wrapepr">
						<ViewSessionRoprt sessionId={eventUsers?.sessionId ?? ''} />
					</div>
				</div>
			);
		} else {
			return (
				<>
					<div className="Events--header">
						<h2>Users</h2>
					</div>
					<div className="EventsList--Users">
						{users.map((eventuser: IEventUser) => (
							<EventUserCard
								key={eventuser._id}
								{...eventuser}
								handleClose={handleClose}
								onViewSession={onViewSession}
								users={eventuser}
								qrIds={qrIds}
								onStatusChange={onStatusChange}
							/>
						))}
					</div>
				</>
			);
		}
	};

	if (apiLoading) {
		return (
			<div className="EventsList--loading-body">
				<UserRoleSkeletonLoader />
			</div>
		);
	}

	if (!eventsState.length) {
		return (
			<div className="user-sumary--empty-state EventsList--empty-body">
				<Image
					fileName="billing-detail-illustration.svg"
					className="Billing-detail-empty-image"
				/>
				<h4>No Event Available</h4>
			</div>
		);
	}

	return (
		<div className="EventsList">
			{eventsState.map(eventCard => (
				<EventCard
					key={eventCard._id}
					name={eventCard.name}
					description={eventCard.description}
					image={eventCard.image}
					date={eventCard.date}
					address={eventCard.address}
					qrIds={eventCard.qrIds}
					_id={eventCard._id}
					handleOpen={handleOpen}
					users={eventCard.users}
					openQrModal={openQrModal}
				/>
			))}

			{isShowModalUser && (
				<Modal
					isOpen={isShowModalUser}
					modalName="Events"
					closeModal={handleClose}
					className="Events--Modal-Users"
					showCloseBtn
					isStopOutsideClick={false}
					optionalClassName="Events--Modal"
				>
					{renderViewBody()}
				</Modal>
			)}

			{isShowQRModal && (
				<Modal
					isOpen={isShowQRModal}
					modalName="QRModal"
					closeModal={handleClose}
					showCloseBtn
					isStopOutsideClick={false}
					optionalClassName="Events--Modal-Users-QR"
				>
					{qrIds.length > 0 ? (
						<QRCode
							// value={`${REACT_APP_KYC_FRONTEND_DOMAIN}/${event.qrIds?.[0]}?type=qr&qrId=${event.qrIds?.[0]}&eventId=${event._id}`}
							value={`https://secure.onyxplus.app/${event.qrIds?.[0]}?type=qr&qrId=${event.qrIds?.[0]}&eventId=${event._id}`}
						/>
					) : (
						<div className="user-sumary--empty-state EventsList--empty-body">
							<Image
								fileName="billing-detail-illustration.svg"
								className="Billing-detail-empty-image"
							/>
							<h4>No QR attach as for now</h4>
						</div>
					)}
				</Modal>
			)}
		</div>
	);
};
