export const API_URL = {
	KYC_SETTINGS: '/kyc-settings',
	ONBOARDED_SUMMARY: '/onboarding-summary',
	CLIENT_DETAILS: '/details',
	INVITES: '/invites',
	CREDENTIAL: '/credentials',
	PIPELINE_INIT: '/pipeline-init',
	PIPELINE_TEMPLATE: '/pipeline-templates',
	PIPELINE_USERS: '/pipeline-users',
	CHECKOUT: '/checkout',
	BUSINESSES: '/businesses',
	CLIENT_LOGIN: '/login',
	CLIENT_SIGNUP: '/users',
	ADD_SIGN_DOC: '/signing/file/upload',
	SIGN_DOC: '/signing/documents',
	USER_ROLES: '/invite-users',
	ROLES: '/business-roles',
	SESSIONS: '/session-lists',
	SESSION_DETAIL: '/session-details',
	ACTIVITIES: '/session-activities',
	INVITE_INVESTOR: '/invite-user',
	INVESTOR: '/investors',
	RESEND_INVITE: '/resend-invite',
	FUNDS: '/funds',
	BILLING: '/onboarding-billings',
	LOGOUT: '/logout',
	INVOICE: '/onboarding-invoices',
	FUND_DETAIL: '/fund-details',
	SAVE_TEMPLATE: '/save-template',
	SIGN_AGREEMENT: '/templates',
	SIGN_AGREEMENT_TEMPLATE_LIST: '/template-list',
	UPLOAD_AGREEMENT_TEMPLATE: '/url/upload',
	SIGN_CONFIG_STATUS: '/sign-configure-status',
	SIGN_AGREEMENT_DOC: '/signing',
	BUSINESS_CARD: '/business-cards',
	BUSINESS_PAYMENT_DETAILS: '/business-payment-details',
	CLIENT_TOKENS: '/tokens',
	BUSINESS_KYB: '/business-kyb',
	FORGOT_PW_MAIL: '/member-email-codes',
	FORGOT_PW_CODE: '/verify-emails',
	FORGOT_PW_RESET: '/reset-password',
	CUSTOM_PRICE: '/custom-pricing',
	GOOGLE_FONDS: '/google-fonts',
	WebAuthLogin: '/web-auth-login',
	PhoneCodes: '/phone-codes',
	WaitingLogin: '/events',
	LoginStatus: '/status',
	VerifyPhones: '/verify-phones',
	Transactions: '/pipeline-transactions',
	GenerateLinkToken: '/link-tokens',
	TokenExchange: '/token-exchange',
	BusinessBankAccounts: '/business-bank-accounts',
	SESSION_REPORTS: '/session-reports',
	GET_UPLOADED_DOCUMENTS: '/session-file-upload/',
	UPLOAD_DOCUMENTS: '/session-file-upload',
	ARCHIVE_SESSION: '/sessions',
	TRANSACTION_REPORTS: '/transaction-reports',
	SESSION_FUND_ASSIGN: '/session-fund-assign',
	SESSION_CONFIGURE: '/session-configurations',
	CLIENT_SOCIAL_LOGIN: '/social-login',
	GA_FUNNEL: '/pipeline-ga-Funnel?from=',
	CHART_AVERAGE_TIME: '/session-average-time?isDeleted=false&from=',
	CLIENT_IDENTITY: '/identities',
	STATE_CITY: '/country-state-city',
	SUPPORT_AGENT: '/agent-analytics',
	TEMPLATE_STATUS: '/template-status',
	MONTHLY_SUBSCRIPTION: '/monthly-subscription',
	USER_LOGIN: '/user-login',
	PHONE_CODES: '/phone-codes',
	VERIFY_CODES: '/verify-codes',
	ENVELOPE: '/envelopes',
	ESIGN_CERTIFICATE: '/esign-certificate',
	ENVELOPE_SIGN: '/envelope-url',
	ENVELOPE_STATUS: '/envelope-status',
	SIGN_INVITE: '/sign-invite',
	MONTHY_SUBSCRIPTION: '/monthly-subscription',
	ATTACHMENT: '/url/attachment',
	SIGN_USER_DASHBOARD: '/sign-dashboard',
	USER_SIGNATURE: '/signatures',
	COMPLEX_INIT: '/complex-init',
	COMPLEX_FLOW: '/complex-flow',
	COMPLEX_INVITE: '/complex-invite',
	COMPLEX_SESSION_INFO: '/session-info',
	COMPLEX_SESSION: '/complex-sessions',
	COMPLEX_SESSION_DETAILS: '/complex-session-details',
	BILLING_TRANSACTION: '/revenue',
	PIPELINE_QR: '/pipeline-qr',
	COMPLEX_QR: '/complex-qrs',
	SUBSCRIPTIONS: '/subscriptions',
	USER_VERIFICATION: '/user-verifications',
	Business_Registration: '/business-registration',
	SandBox_Signup: '/sandbox-user-registration?email=',
	Payment_Methods: '/payment-methods',
	Session_Nodes: '/session-nodes',
	COMPLEX_SESSION_NODES: '/complex-session-nodes',
	STEP_TEMPLATES: '/step-templates',
	SUBSCRIPTION_PRICE: '/pricing',
	PACKET_EDIT: '/packet-edit',
	SUB_ACCOUNT: '/sub-account',
	DEALS: '/deals',
	EDIT_RECIPIENTS: '/edit-recipients',
	BUSINESS_COMPLIANCE: '/business-compliance',
	BUSINESS_COMPLIANCE_ID: '/business-compliance?businessId=',

	// MFA
	MFA_VERIFY: '/mfa-verify',
	MFA_SETUP: '/mfa-setup',
	BACK_UP_CODES: '/mfa-backup-codes',

	// Encrption Key
	EncryptKey: '/encryption-key',

	// Verify Refresh Token
	VERIFY_REFRESH_TOKEN :'/access-tokens',

	//dummy apis
	ESCROW_TRANSACTION: '/escrow-transaction',
	ESCROW_ACCOUNT: '/escrow-accounts',
	PIPELINE_PRICING: '/pipeline-pricing?pipelineId',
	ENVELOPE_HISTORY: '/envelope-logs',
	SIGNATURE_FONT: '/signatures',
	PACKET_DETAILS: '/packet-details',
	RECIPIENT_KYC: '/recipient-kyc',
	DOCUMENTS: '/documents',
	CHEQUE_DEFENCE_TRANSACTION: '/check-defense-transaction',
	CHEQUE_DEFENCE_SESSION: '/check-defense-session',
	CHEQUE_DEFENCE_BANK: '/check-defense-bank',
	USER_ACCESS_TOKEN: '/user-access-tokens',
	MEDIA: '/media',
	RESEND_DOC_INVITES: '/resend-docs-invites',
	UPLOAD_PROOF: '/upload-proof',
	PING: '/ping',
	INVOICES: '/invoices',
	RESEND_DOCUMENT: '/resend-document',
	QR_FLOWS: '/qr-invite',
	PROVIDERS: '/providers',
	NODE_STEPS: '/node-steps',
    PRE_SIGN_UPLOAD: '/pre-signed-upload', // Endpoint to retrieve a pre-signed upload URL

	DRAFT_ENVELOPE: '/draft-envelope',
	BIOMETRICS: '/biometrics'
};

export const GOOGLE_API = {
	MAP: 'https://www.google.com/maps/search/?api=1&query=',
	IP: 'https://us-central1-glossy-fastness-305315.cloudfunctions.net/locate',
};

//captable apis
export const GET_CAPTABLE = {
	BASIC_INFORMATION_URL:
		'https://api.stage.satschel.com/v2/captable/basic-information',
	BASIC_INFORMATION: '/basic-information',
	SETUP: '/setup',
	STAKEHOLDER: '/simplici-stakeholder',
};
