import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Image } from '@storybook';
import {
	ApprovalStatusState,
	CountryCode,
	EmailCredsState,
	LoginCredsState,
	OptionsResponseState,
	ScreenLoader,
	WEBAUTH_URL,
	getWebAuthnSupported,
	useWebLogin,
} from 'components';
import { API_URL, MESSAGE } from 'constant';
import { useNetwork, useNotification, useTimer, useWebAuthn } from 'hooks';
import { userVerificationId } from 'global-stores';
import { useSignup } from '../signup';
import { currentVerifySteps, verifyStepper } from './contants';

import { ROUTES } from 'routes';
import { useNavigate } from 'react-router-dom';
import { Messages } from 'components/login/components/signin-form/constant';
import { EmailVerification } from './email-verification';
import { BiometricAuthInfo } from './biometric-auth-info';
import classNames from 'classnames';
import { ProgressStepper } from '@storybook/progress-stepper';
let userApiCall: any = null;
import { isSkipPhoneEnabled } from 'utils';

interface IVerifyModal {
	visible: boolean;
	handleModal?: () => void;
	regEmail: string;
}

// constants
const timeInterval = 1000; // 1 second

export const VerifyModal: FC<IVerifyModal> = ({
	visible,
	handleModal = () => ({}),
}) => {
	// globle states
	const country = useRecoilValue(CountryCode);
	const phone = useRecoilValue(LoginCredsState);
	const verificationId = useRecoilValue(userVerificationId);
	const optionsResponse = useRecoilValue(OptionsResponseState);
	const verficationStatus = useRecoilValue(ApprovalStatusState);
	const [emailCredsState, setEmailCredsState] = useRecoilState(EmailCredsState);
	const [webAuthFailed, setWebAuthFailed] = useState(false);

	// hooks
	const {
		registrationOptionsWithToken,
		verifyRegistrationWithToken: verifyRegistration,
	} = useWebLogin();
	const { get } = useNetwork();
	const { post: userLogin } = useNetwork({ returnResponse: true });
	const { get: reinviteUser } = useNetwork({ updateState: false });

	// const { set: setCookie } = useCookie();
	const { userVerfication, fetchUserDetails } = useSignup();
	const { errorNotification, successNotification } = useNotification();
	const { registerNewCredential } = useWebAuthn();
	const navigate = useNavigate();
	const urlParams = useMemo(() => window.location.search, []);
	// local states
	const [resendCount, setResendCount] = useState(1);

	const [isUserVerified, setIsUserVerified] = useState<any>();
	const [currentStep, setCurrentStep] = useState(
		isSkipPhoneEnabled() ? currentVerifySteps.EmailVerification :currentVerifySteps.verifyPhone 
	);
	const [disable, setDisable] = useState(false);
	const [renderBtnLabel, setRenderBtnLabel] = useState('');
	const [tempToken, setTempToken] = useState('');
	const [isWebAuthnCompleted, setWebAuthnCompleted] = useState(false);
	const { seconds, setSeconds } = useTimer();

	const waitingForValidation = useCallback(() => {
		get(
			`${API_URL.USER_VERIFICATION}?countryCode=${country}&phone=${phone}`
		).then(res => {
			if (res?.message === 'ok') {
				setIsUserVerified(res?.data[0]);
			}

			if (res?.data[0]?.phoneNotificationStatus === 'rejected') {
				setIsUserVerified(res?.data[0]);
				setCurrentStep(currentVerifySteps?.verifyReject);
				clearInterval(userApiCall);
			}
			if (res?.data[0]?.phoneNotificationStatus === null) {
				setIsUserVerified(res?.data[0]);
				setCurrentStep(currentVerifySteps?.verifyTimeOut);
				clearInterval(userApiCall);
			}
		});
	}, [country, get, phone]);

	useEffect(() => {
		userApiCall = setInterval(() => {
			if (
				currentStep === currentVerifySteps?.verifyProgress ||
				currentStep === currentVerifySteps?.EmailVerification
			) {

				 if (!isUserVerified?.isVerifiedPhone && !isSkipPhoneEnabled()) {
				 	waitingForValidation();
				 } else if (!isUserVerified?.isVerifiedEmail) {
					setCurrentStep(currentVerifySteps.EmailVerification);
					waitingForValidation();
				} else {
					setCurrentStep(currentVerifySteps.verifySuccess);
					return clearInterval(userApiCall);
				}
			}
		}, 1000);
		return () => clearInterval(userApiCall);
	}, [
		get,
		isUserVerified,
		currentStep,
		errorNotification,
		country,
		phone,
		waitingForValidation,
		setSeconds,
	]);

	useEffect(() => {
		if (currentStep === currentVerifySteps.verifyPhone) {
			setTimeout(() => {
				setCurrentStep(currentVerifySteps.verifyProgress);
			}, 5000);
		}
	}, [currentStep]);

	const onSuccess = useCallback(
		async (token: string) => {
			await fetchUserDetails(token, undefined, undefined, true);
			navigate({ pathname: ROUTES.COMPLETED, search: urlParams });
		},
		[fetchUserDetails, navigate, urlParams]
	);

	const renderDashboardScreen = useCallback(
		async (from?: 'close') => {
			setDisable(true);
			if (from === 'close' || !(await getWebAuthnSupported())) {
				setWebAuthnCompleted(true);
			}
			const payload = {
				type: 'getTokenWithCode',
				verificationId: verificationId,
			};

			await userLogin(API_URL.USER_LOGIN, payload)
				.then(async res => {
					const { errorData } = res ?? {};
					if (res?.token) {
						setTempToken(res?.token);
						if (from === 'close') {
							setWebAuthnCompleted(true);
							onSuccess(res?.token);
							return;
						}
						if (await getWebAuthnSupported()) {
							await registrationOptionsWithToken(res?.token);
							return;
						}
						setWebAuthnCompleted(true);
						onSuccess(res?.token);
					} else {
						if (tempToken) {
							if (from === 'close') {
								setWebAuthnCompleted(true);
								onSuccess(tempToken);
								return;
							}
							if (await getWebAuthnSupported()) {
								await registrationOptionsWithToken(tempToken);
								return;
							}
							setWebAuthnCompleted(true);
							onSuccess(tempToken);
						} else {
							errorNotification(errorData?.message ?? MESSAGE.ERROR);
							navigate(ROUTES.LOGIN);
						}
					}
				})
				.finally(() => {
					setDisable(false);
				});
		},
		[
			verificationId,
			userLogin,
			onSuccess,
			registrationOptionsWithToken,
			tempToken,
			errorNotification,
			navigate,
		]
	);

	const handleRegistrastionSuccess = useCallback(
		async (resp: any) => {
			if (resp) {
				setWebAuthnCompleted(true);
				const payloadSaveCredential = {
					type: 'verifyRegistrationUID',
					registrationOptResponse: resp,
					id: optionsResponse?.id,
					deviceInfo: navigator.userAgent ?? {},
				};
				const response = await verifyRegistration(payloadSaveCredential);
				const { isRegistered, token } = response ?? {};
				if (isRegistered && token) {
					successNotification('Web authentication is registered successfully');
					setWebAuthnCompleted(true);
					onSuccess(token);
					setWebAuthFailed(false);
					return;
				}
				setWebAuthnCompleted(false);
				setWebAuthFailed(true);
				errorNotification('Web authentication registration failed.');
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[optionsResponse]
	);

	useEffect(() => {
		if (optionsResponse?.registrationOptions) {
			registerNewCredential(
				handleRegistrastionSuccess,
				optionsResponse?.registrationOptions ?? {}
			);
		}
		// eslint-disable-next-line
	}, [optionsResponse]);

	useEffect(() => {
		if (verficationStatus.length) {
			const interval = setInterval(async () => {
				const verficationUrl = `${WEBAUTH_URL.CLIENT_VERIFY_PHONE}/${verficationStatus}`;
				const response = await get(verficationUrl);
				const { status } = response?.data ?? {};
				if (status === 'approved') {
					renderDashboardScreen();
					clearInterval(interval);
				}
				if (status === 'rejected') {
					setDisable(false);
					setCurrentStep(currentVerifySteps?.verifySuccess);
					clearInterval(interval);
				}
				if (response?.message !== 'ok') {
					clearInterval(interval);
					setDisable(false);
				}
			}, timeInterval);
			return () => {
				clearInterval(interval);
			};
		} else {
			return () => {}; //eslint-disable-line
		}
	}, [verficationStatus, renderDashboardScreen, get]);

	const isResendAllowed = useMemo(() => !seconds, [seconds]);

	const handleResend = useCallback(async () => {
		if (!isResendAllowed || resendCount > 3) return;
		setResendCount(prev => prev + 1);
		if (resendCount === 1) {
			setSeconds(40);
		} else if (resendCount === 2) {
			setSeconds(60);
		}
		const payload = {
			phone,
			sendSMS: true,
			countryCode: country,
		};
		await userVerfication(payload);
	}, [
		isResendAllowed,
		resendCount,
		phone,
		country,
		userVerfication,
		setSeconds,
	]);

	const handleRetry = useCallback(async () => {
		setCurrentStep(currentVerifySteps.verifyPhone);
		// reset timer after re try
		setSeconds(20);
		setResendCount(1);

		const payload = {
			phone,
			sendSMS: true,
			countryCode: country,
		};
		await userVerfication(payload);
	}, [setSeconds, phone, country, userVerfication]);

	useEffect(() => {
		async function updateBtnLabel() {
			const isWebAuthn = await getWebAuthnSupported();
			const label = isWebAuthn ? 'Register passkey' : 'Continue';
			setRenderBtnLabel(label);
		}
		updateBtnLabel();
	}, [renderBtnLabel]);

	const formattedSeconds = useMemo(
		() => (seconds < 10 ? `0${seconds}` : seconds),
		[seconds]
	);

	const onClickIntercome = () => {
		(window as any)?.Intercom?.(
			'showNewMessage',
			'Unable to Login, Not receiving message'
		);
	};

	const handleResendEmail = useCallback(async () => {
		if (!isResendAllowed || resendCount > 3) return;

		if (resendCount === 1) {
			setSeconds(40);
		} else if (resendCount === 2) {
			setSeconds(60);
		}
		const response = await reinviteUser(
			`${API_URL.USER_ROLES}?email=${encodeURIComponent(
				emailCredsState
			)}&sendEmail=true`
		);

		if (response.message?.toLowerCase() === 'ok') {
			successNotification('A verification link has been sent to your email ');
		} else {
			errorNotification(response?.message ?? Messages.UnExpectedError);
			setCurrentStep(currentVerifySteps.verifyReject);
		}
	}, [
		emailCredsState,
		errorNotification,
		isResendAllowed,
		reinviteUser,
		resendCount,
		setSeconds,
		successNotification,
	]);

	const initResendEmail = useCallback(async () => {
		if (!emailCredsState) return;
		const response = await reinviteUser(
			`${API_URL.USER_ROLES}?email=${encodeURIComponent(
				emailCredsState
			)}&sendEmail=true`
		);

		if (response.message?.toLowerCase() === 'ok') {
			successNotification('A verification link has been sent to your email ');
		} else {
			errorNotification(response?.message ?? Messages.UnExpectedError);
			setCurrentStep(currentVerifySteps.verifyReject);
		}
	}, [emailCredsState, errorNotification, reinviteUser, successNotification]);

	useEffect(() => {
		if (
			isUserVerified?.phoneNotificationStatus === 'approved' &&
			currentStep === currentVerifySteps.verifyProgress
		) {
			const { email } = isUserVerified ?? {};
			setSeconds(20);
			setResendCount(1);
			if (email) {
				setEmailCredsState(email);
				initResendEmail();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStep, isUserVerified, setEmailCredsState, setSeconds]);

	const verifyPhoneContent = useCallback(() => {
		return (
			<>
				<div className="verify-modal__image">
					<Image fileName="verifyPhone.svg" />
				</div>
				<div className="verify-modal__text-container">
					<div className="verify-modal__title-wrapper">
						<span className="title">Verify your phone number</span>
						<div className="sub-title">
							<span>We have sent you a text message to </span>
							<b>
								{country} {phone}
							</b>{' '}
							<span
								className="verify-modal__progressBar-wrapper__edit"
								onClick={() => handleModal()}
							>
								<i className="ri-pencil-fill" /> Edit
							</span>
						</div>
					</div>
					<div className="verify-modal__description">
						<span>
							Kindly click on the link provided in the text message and complete
							the verification process from there.
						</span>
					</div>
					<div className="verify-modal__footer verify-modal__footer_verify verify-modal--resend-body">
						{resendCount !== 4 ? (
							<>
								{isResendAllowed ? (
									<div className="verify-modal__footer--resend">
										<span className="footer-text">
											Didn&apos;t receive the text message?
										</span>
										<span
											className="footer-resend-btn"
											onClick={() => handleResend()}
										>
											Resend
										</span>
									</div>
								) : (
									<div className="verify-modal__footer">
										<span className="footer-text">
											Time remaining for next OTP 00:{formattedSeconds}
										</span>
									</div>
								)}
							</>
						) : (
							<div
								className="verify-modal__footer verify-modal--connect"
								onClick={onClickIntercome}
							>
								<div className="verify-modal--icon">
									<i className="ri-user-voice-line"></i>
								</div>
								<span className="footer-resend-btn">
									Facing trouble? Click here for assistance.
								</span>
							</div>
						)}
					</div>
				</div>
			</>
		);
	}, [
		country,
		phone,
		resendCount,
		isResendAllowed,
		formattedSeconds,
		handleModal,
		handleResend,
	]);

	const verifyRejectContent = useCallback(() => {
		return (
			<>
				<div className="verify-modal__image">
					<i className="ri-close-circle-fill" />
				</div>
				<div className="verify-modal__verify-text-container">
					<div className="verify-modal__title-wrapper">
						<span className="title">Phone number verification failed</span>
						<div className="sub-title">
							<span>Your phone number verification has failed.</span>
						</div>
					</div>
					<div style={{ width: '121px' }}>
						<Button
							label={'Retry'}
							type="button__filled button__filled--secondary button__small button__block"
							handleClick={handleRetry}
						/>
					</div>
				</div>
			</>
		);
	}, [handleRetry]);

	const verifySuccessContent = useCallback(() => {
		return (
			<>
				<div className="verify-modal__image">
					<Image fileName="biometric.svg" />
				</div>
				<div className="verify-modal__text-container">
					<div className="verify-modal__title-wrapper">
						<span className="title">Register for device biometric</span>
						<div className="sub-title">
							<span>
								To enhance your security and streamline the login process,
								register your device for biometric login.
							</span>
						</div>
					</div>
					<div className="verify-modal__description">
						<span>
							Click the register passkey button below and follow the on-screen
							instructions.
						</span>
					</div>
				</div>
				<div>
					<Button
						label={renderBtnLabel}
						type="button__filled--primary button__large"
						handleClick={() => renderDashboardScreen()}
						disabled={disable}
					/>
				</div>
				{/* Pradeep chaurasia : Screen loader added between webauthn completion and redirect dashboard */}
				<ScreenLoader visible={isWebAuthnCompleted} />
			</>
		);
	}, [renderDashboardScreen, disable, renderBtnLabel, isWebAuthnCompleted]);

	const verifyEmailBody = useMemo(
		() => (
			<EmailVerification
				resendCount={resendCount}
				isResendAllowed={isResendAllowed}
				handleResend={handleResendEmail}
				formattedSeconds={formattedSeconds}
				onClickIntercome={onClickIntercome}
				email={emailCredsState}
			/>
		),
		[
			emailCredsState,
			formattedSeconds,
			handleResendEmail,
			isResendAllowed,
			resendCount,
		]
	);

	const verifyTimeOutContent = useCallback(() => {
		return (
			<>
				<div className="verify-modal__image">
					<i className="ri-alert-fill" />
				</div>
				<div className="verify-modal__verify-text-container">
					<div className="verify-modal__title-wrapper">
						<span className="title">Session timeout</span>
						<div className="sub-title">
							<span>Your session has timed-out. Please try again.</span>
						</div>
					</div>
					<div style={{ width: '121px' }}>
						<Button
							label={'Retry'}
							type="button__filled button__filled--secondary button__small button__block"
							handleClick={handleRetry}
						/>
					</div>
				</div>
			</>
		);
	}, [handleRetry]);

	const biomatricAuthInfo = useMemo(() => {
		return <BiometricAuthInfo />;
	}, []);

	const verifyModalStepper = useMemo(() => {
		let activeStep = '';
		let isVerifed = true;
		switch (currentStep) {
			case currentVerifySteps.verifyPhone:
			case currentVerifySteps.verifyProgress:
				activeStep = currentVerifySteps.verifyPhone;
				break;
			case currentVerifySteps.verifySuccess:
				activeStep = currentStep;
				break;
			case currentVerifySteps.EmailVerification:
				activeStep = currentStep;
				break;
			case currentVerifySteps.verifyReject:
				activeStep = currentVerifySteps.verifyPhone;
				isVerifed = false;
				break;
		}
		return (
			<ProgressStepper
				steps={isSkipPhoneEnabled() ? verifyStepper.slice(1) : verifyStepper }
				activeStep={activeStep}
				isVerifed={isVerifed}
			/>
		);
	}, [currentStep]);

	const renderVerifyModalContent = useCallback(() => {
		switch (currentStep) {
			case currentVerifySteps.verifyPhone:
			case currentVerifySteps.verifyProgress:
				return verifyPhoneContent();
			case currentVerifySteps.verifySuccess:
				if (webAuthFailed) {
					return biomatricAuthInfo;
				} else {
					return verifySuccessContent();
				}
			case currentVerifySteps.verifyReject:
				return verifyRejectContent();
			case currentVerifySteps.verifyTimeOut:
				return verifyTimeOutContent();
			case currentVerifySteps.EmailVerification:
				return verifyEmailBody;

			default:
				return verifyPhoneContent();
		}
	}, [
		currentStep,
		verifyEmailBody,
		biomatricAuthInfo,
		webAuthFailed,
		verifyPhoneContent,
		verifyRejectContent,
		verifySuccessContent,
		verifyTimeOutContent,
	]);

	if (!visible) {
		return null;
	}

	const verifyModalContainer = classNames('verify-modal__container', {
		'biometric-info-modal__container': webAuthFailed,
	});

	const closeWrapper = classNames('verify-modal__closeIcon-wrapper', {
		'verify-modal__closeIcon-wrapper-right':
			currentStep === currentVerifySteps.verifySuccess,
	});

	return (
		<div className="verify-modal__main">
			<div className={verifyModalContainer}>
				<div className={closeWrapper}>
					{currentStep === currentVerifySteps.verifySuccess && (
						<span
							className="verify-modal__skipIcon"
							onClick={
								currentStep === currentVerifySteps.verifySuccess
									? () => renderDashboardScreen('close')
									: handleModal
							}
						>
							Skip
						</span>
					)}

					{(currentStep === currentVerifySteps.verifyPhone ||
						currentStep === currentVerifySteps?.verifyProgress ||
						currentStep === currentVerifySteps?.verifyReject) && (
						<span
							className="verify-modal__closeIcon-text"
							onClick={
								currentStep === currentVerifySteps.verifySuccess
									? () => renderDashboardScreen('close')
									: handleModal
							}
						>
							<i className="ri-arrow-left-s-line verify-modal__closeIcon" />
							Back
						</span>
					)}
				</div>
				{verifyModalStepper}
				{renderVerifyModalContent()}
			</div>
		</div>
	);
};

// export default VerifyModal;