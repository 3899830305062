import { Fragment, useCallback, useMemo } from 'react';
import { Message } from 'components';
import { useRecoilValue } from 'recoil';
import { ComplexSettingFormState } from 'views/pipeline/store';
import { AddedActionsState, useOnboardingHook } from 'views/onboarding-flow';
import { ConfigActionKeys, ElementsToRemove } from 'views/pipeline/constants';
import { proofReadingKey } from 'views/pipelines';
import { SuccessKybKeyRemoved } from '../../constants';
import { REGEX } from 'constant';

export const ComplexPriceBreakDown = () => {
	const complexSettingForm = useRecoilValue(ComplexSettingFormState);
	const addedActions = useRecoilValue(AddedActionsState);

	const { isPricingHide } = useOnboardingHook();

	// Function to remove objects with duplicate "key" property, specifically "formAction"
	const removeFormActionDuplicates = (array: unknown[]) => {
		const seenKeys = new Set();
		return array.filter((item: any) => {
			const key = item.key;
			if (key === 'formAction' && seenKeys.has(key)) {
				return false;
			}
			seenKeys.add(key);
			return true;
		});
	};

	//Shahbaaz: complex logic for getting action values for complex
	const complexAction = useMemo(() => {
		const orignalObj = Object.values(complexSettingForm ?? {})
		const foundSuccessScreenIndex = orignalObj.findIndex(
			(obj: any) => Object.prototype.hasOwnProperty.call(obj, 'appStoreUrl')
		);
		if(foundSuccessScreenIndex > -1){
			orignalObj.splice(foundSuccessScreenIndex, 1, {successScreen: true})	
		}
		// Shahbaaz:remove duplicate key form array
		const removalDuplicateResult = removeFormActionDuplicates(orignalObj);

		const copyObj = structuredClone(removalDuplicateResult);

		const newData = copyObj.map((item: any) => {
			if (item?.accreditationDocument) {
				delete item?.accreditationDocument;
			}
			return item;
		});

		//Shahbaaz: Filter only object properties which have some value in it
		const filterData = newData.filter(
			(item: any) => Object.keys(item).length > 0
		);
		//Shahbaaz: Filter only object properties
		const filteredArray = filterData.filter(
			(item: any) => typeof item === 'object'
		);
		const formActionIdx = filteredArray.findIndex(
			(item: any) => item?.key === ConfigActionKeys.FormAction
		);

		//Shahbaaz: Deleting form action object inside the filteredArray
		if (formActionIdx !== -1) {
			filteredArray.splice(formActionIdx, 1);
		}

		//Shahbaaz: convert all key the into array of string
		const keys = new Set();
		const arrayValue: any = [];

		filteredArray.forEach((obj: any) => {
			for (const key in obj) {
				if (obj[key] === true) {
					keys.add(key);
				}
				if (typeof obj[key] === 'string' && isNaN(Number(obj[key]))) {
					arrayValue.push(obj[key]);
				}
				if (key === ConfigActionKeys.AccreditationType) {
					const accDataee = filteredArray.filter(
						(el: { [x: string]: any }) => el[key]
					);
					accDataee?.forEach((item: any) => {
						arrayValue.push(item?.accreditationType?.value);
					});
				}
			}
		});
		//Shahbaaz: concat both keys into single array
		const result = [...Array.from(keys), ...arrayValue];

		//Shahbaaz: Remove gargabe value from result
		const uniqueResult = result.filter(
			item => !ElementsToRemove.includes(item)
		);

		//Shahbaaz: Initialize variables to keep track of the maximum true count and corresponding object
		let maxTrueCount = 0;
		let objectWithMaxTrue: any = null;

		//Shahbaaz: Iterate through the array and find the object with the most true values
		filteredArray.forEach(
			(obj: ArrayLike<unknown> | { [s: string]: unknown }) => {
				const trueCount = Object.values(obj).filter(
					value => value === true
				).length;
				if (trueCount > maxTrueCount) {
					maxTrueCount = trueCount;
					objectWithMaxTrue = obj;
				}
			}
		);

		filteredArray.filter((obj: { [x: string]: boolean }) => {
			for (const key in obj) {
				if (obj[key] === true) {
					uniqueResult.push(key);
				}
			}
		});

		//Shahbaaz: push max no. of true value inside the oject
		if (objectWithMaxTrue) {
			const keysObjectWithMaxTrue = Object.keys(objectWithMaxTrue).filter(
				key => objectWithMaxTrue[key]
			);
			keysObjectWithMaxTrue.forEach(el => {
				uniqueResult.push(el);
			});
		}

		if (formActionIdx !== -1) {
			uniqueResult.push(ConfigActionKeys.Form);
		}
		const finalResult = Array.from(new Set(uniqueResult));

		//Arun:Paras Remove KYB value from result becuse we need the configation only to show in price break down and removed success screen keys and url

		let completeData = finalResult.filter(
			item => !SuccessKybKeyRemoved.includes(item)
		);
		completeData =completeData.filter(item => !REGEX.isValidUrl.test(item) && REGEX.isValidKey.test(item));
		return completeData;
	}, [complexSettingForm]);

	const getTableLabel = (key: string) => {
		switch (key) {
			case 'esign':
				return 'Sign Agreement (esign)';
			case 'docusign':
				return 'Sign Agreement (DocuSign)';
			case 'successScreen':
				return 'Success Screen';
			// Shahbaaz:chnage key due to remove config for kyb
			case 'Kyb':
				return 'KYB';
			case 'payOut':
				return 'Pay out';
			case 'payIn':
				return 'Pay In';
			case 'addressVerification':
				return 'Address Verification, Name, DOB';
			case 'idv':
				return 'IDV - Document Verification';
			case 'chequeFraud':
				return 'Check Defense';
			case 'ssn':
				return 'SSN';
			case '506b':
				return 'Accreditation (506b)';
			case '506c':
				return 'Accreditation (506c)';
			case 'form':
				return 'Questionnaire';
			case 'elements':
				return 'KYB Documents';
			case 'amlVerification':
				return 'Anti money laundering(AML)';
			// Arun kumar : Enmus for Kyb configs
			case 'identity':
				return 'Individual or Business';
			case 'faceEnrollment':
				return 'Facial Enrollment';
			case 'palmEnrollment':
				return 'Palm Enrollment';
			case 'kyb_enhanced':
				return 'KYB Enhanced';
			case 'kyb_comprehensive':
				return 'KYB Comprehensive';
			case 'dun-brad-street-section':
				return 'Dun & Bradstreet';
			case 'kyb_physical_verification':
				return 'KYB Physical Verification';
			case 'civil-suits':
				return 'Civil Suits';
			case 'credit_check':
				return 'Credit Check';
			case 'background_check':
				return 'Background Check';
			case 'representative_kyc_aml_verification':
				return 'Representative KYC & AML Verification';
			case 'kyb-negative-filling':
				return 'KYB Negative Filling';
			case 'business_sanctions_ofac':
				return "Business Sanctions and OFAC"	
			case 'creditReport':
				return 'Credit Report';
			case 'proofReading':
				return proofReadingKey.DocumentReview;
			case 'kycComprehensive':
				return 'KYC Comprehensive';
			case 'kybSubsidiary':
				return 'KYB for Subsidiaries';
			case 'oneTime':
				 return "One Time AML";
			case 'dailyOnGoing':
				return "Daily on Going AML";	 	
			case 'amlFrequency': 
				return "AML Frequency";
			default:
				return key;
		}
	};

	const getPrice = useCallback(
		(key: string) => {
			let metaPrice = 0;
			let metaData: any = [];
			switch (key) {
				case 'payIn':
				case 'payOut':
					metaPrice = addedActions.find(
						el => el.key === ConfigActionKeys.FundInvestmentVerification
					)?.price;

					return metaPrice;
				case 'docusign':
				case 'esign':
					metaData = addedActions.find(
						el => el.key === ConfigActionKeys.SignAgreementVerification
					)?.metadata[0]?.options;
					metaPrice = metaData?.find((el: any) => el.key === key)?.price;
					return metaPrice;
				case 'addressVerification':
				case 'idv':
				case 'ssn':
				case 'kycComprehensive':
					metaData = addedActions.find(
						el => el.key === ConfigActionKeys.AllId
					)?.metadata;
					metaPrice = metaData?.find((el: any) => el.key === key)?.price;
					return metaPrice;
				case '506b':
				case '506c':
					metaData = addedActions.find(
						el => el.key === ConfigActionKeys.AccreditationVerify
					)?.metadata[0]?.options;
					metaPrice = metaData?.find((el: any) => el.value === key)?.price;
					return metaPrice;
				// Shahbaaz: Adding key for showing kyb price
				case 'kybMatches':
				case 'Kyb':
					metaPrice = addedActions.find(
						el => el.key === ConfigActionKeys.KybVerification
					)?.price;
					return metaPrice;
				case 'form':
					return metaPrice;
				case 'elements':
					return metaPrice;
				case 'oneTime':
				case 'dailyOnGoing':
					metaData = addedActions.find(
						el => el.key === ConfigActionKeys.AmlVerification
					)?.metadata[0]?.options;
					metaPrice = metaData?.find((el: any) => el.key === key)?.price;
					return metaPrice;
				case 'identity':
					return metaPrice;
				// Arun kumar : Getting prices value for KYB
				case 'kyb_enhanced':
				case 'kyb_comprehensive':
				case 'kyb_physical_verification':
				case 'civil-suits':
				case 'credit_check':
				case 'representative_kyc_aml_verification':
				case 'background_check':
				case 'kyb-negative-filling':
				case 'dun-brad-street-section':
				case 'kybSubsidiary':
				case 'business_sanctions_ofac':
					metaData = addedActions?.find(
						el => el.key === ConfigActionKeys.KybVerification
					)?.metadata;
					metaPrice = metaData?.find((el: any) => el.key === key)?.price;
					return metaPrice;

				default:
					return 0;
			}
		},
		[addedActions]
	);
	const getTableRow = useMemo(
		() => (
			<Fragment>
				{complexAction?.map(item => (
					<tr key={item}>
						<>
							<td>
								<div className="details__table__label">
									{getTableLabel(item)}
								</div>
							</td>
							{isPricingHide && (
								<td className="details__table__right">
									<div className="details__table__right__price">
										{new Intl.NumberFormat('en-US', {
											style: 'currency',
											currency: 'USD',
										})?.format(getPrice(item) ?? 0)}
										<span>/Verification</span>
									</div>
								</td>
							)}
						</>
					</tr>
				))}
			</Fragment>
		),
		[complexAction, getPrice, isPricingHide]
	);
	const publishOnboardingFlow = useMemo(
		() => (
			<div className="details">
				<div className="details__info">
					<div className="details__info__title">Item pricing</div>
					<div className="details__info__description">
						Pricing of every action in the flow.
					</div>
				</div>
				<Message
					text={
						'Note: We charge you verification fee even if verification results in rejection.'
					}
					varient="warning"
				/>
				<table className="react-table-responsive details__table">
					<thead className="react-table-responsive__head">
						<tr className="react-table-responsive__head-row">
							<th className="table__head-data details__table__header-label">
								Action
							</th>
							{isPricingHide && (
								<th className="table__head-data details__table__header-label details__table__right">
									Price
								</th>
							)}
						</tr>
					</thead>
					<tbody className="react-table-responsive__body">{getTableRow}</tbody>
				</table>
			</div>
		),
		[getTableRow, isPricingHide]
	);

	return <>{publishOnboardingFlow}</>;
};
