import { Button, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { useNotification } from 'hooks';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
	ActiveActionKeyNameState,
	IAction,
	IsPipelineFlowErrorMessage,
	OpenInviteInvestorModal,
	PipelineMasterSteps,
	PipelineSettingsFormState,
	SelectedOnboardingAction,
} from 'global-stores';
import { useSetComplex } from 'views/complex-onboarding-flow/hooks/use-complex';
import {
	ComplexConfigFromState,
	ComplexFlowUnconfigedState,
	IComplexFlowUnconfiged,
	NodesState,
} from 'views/complex-onboarding-flow/stores/states';
import {
	AddedFileDataState,
	ConfiguredStepState,
	CurrentStepState,
	OnboardingFooterNavigateState,
	SelectedStepsState,
	UnConfiguredStep,
} from 'views/onboarding-flow/store';
import {
	ComplexSettingFormState,
	PipelineBody,
	UnitPriceInputErrorState,
	UnitPriceInputValueState,
	UnitPriceToggleState,
	uploadIsRequired,
} from 'views/pipeline';
import './config-modal.scss';
import { SessionTreeGraph } from 'views/complex-onboarding-flow';
import { useComplexPublishAPI } from '../../publish/complex-publish-api';
import { KybPriceTooltip , IKybPricingDetails} from '../../kyb-price-info-tooltip';
import { REGEX } from 'constant';
interface IProps {
	isOpen: boolean;
	setIsOpen: (is: boolean) => void;
	className?: string;
}

let kybStepPrice: Partial<IKybPricingDetails> = {
	addressVerification: "0",
	business_sanctions_ofac: "0",
	chequeFraud: "0",
	idv: "0",
	kybSubsidiary: "0",
	kyb_comprehensive: "0",
	kyb_enhanced: "0",
	kycComprehensive: "0",
};

export const ConfigureModal: FC<IProps> = ({
	isOpen: configType,
	setIsOpen,
	className
}) => {
	const { label, key, id = '' } = useRecoilValue<IAction>(CurrentStepState);
	const ActiveActionKeyName = useRecoilValue(ActiveActionKeyNameState);
	const setIsOpenModal = useSetRecoilState(OpenInviteInvestorModal);
	const addedFiles = useRecoilValue(AddedFileDataState);
	const nodes = useRecoilValue(NodesState);
	const navigate = useRecoilValue(OnboardingFooterNavigateState);
	const settingsForm = useRecoilValue(PipelineSettingsFormState);
	const [unOpennedConfig, setUnOpennedConfig] =
		useRecoilState(UnConfiguredStep);
	const [configuredStep, setConfiguredStep] =
		useRecoilState(ConfiguredStepState);
	const setComplexFlowConfiged = useSetRecoilState(ComplexFlowUnconfigedState);
	const complexConfigFromState = useRecoilValue(ComplexConfigFromState);
	const complexSettingForm = useRecoilValue(ComplexSettingFormState);

	const pipelinesSteps = useRecoilValue(PipelineMasterSteps);
	const setIsInputError = useSetRecoilState(IsPipelineFlowErrorMessage);
	const unitPriceToggleOn = useRecoilValue(UnitPriceToggleState);
	const unitPriceInputValue = useRecoilValue(UnitPriceInputValueState);
	const setIsUnitPriceInputError = useSetRecoilState(UnitPriceInputErrorState);
	
	const [checkingUrl, setCheckingUrl] = useState(false);
	const mobileUiRef = useRef<null | HTMLElement>(null);

	// kyc and kyb Total cost using the key from pipelineSteps
	const getFilteredData = useCallback((key: string) => {
		return pipelinesSteps.filter(item => item.key === key);
	}, [pipelinesSteps]);
	const kybData = getFilteredData('kyb');
	const kycData = getFilteredData('kyc');

	//   className toggle to keep button in flex-end 
	//   allId is for kyc Verification
	const kybKycClassName = useMemo(() => {
		return key === 'kybVerification' || key === 'allId'
			? 'ConfigureModal--footers'
			: 'ConfigureModals--footer';
	}, [key]);

	const getTotalPrice = (data: any[]) => {
		let totalPrice = 0;
		if (data && data?.length > 0) {
			data?.forEach(item => {
				item?.actions?.forEach((action: { metadata: any[] }) => {
					action.metadata.forEach(metadata => {
						const isExcluded =
							metadata.key === 'kybSubsidiary' || metadata.key === 'representative_kyc_aml_verification';
						const kybStepKey = metadata.key
						kybStepPrice = { ...kybStepPrice, [kybStepKey]: metadata.price.toFixed(2) }
						if (
							!isExcluded && metadata?.price &&
							(
								(id && complexSettingForm?.[id]?.[metadata?.key]) ||
								(!id && settingsForm?.[metadata?.key])
							)
						) {
							totalPrice += metadata.price;
						}
					});
				});
			});
		}
		return totalPrice.toFixed(2);
	};
	const kybTotalPrice = getTotalPrice(kybData);
	const kycTotalPrice = getTotalPrice(kycData);
	
	const selectedStepsState = useRecoilValue(SelectedStepsState)	
	const selectedOnboardingFlow = useRecoilValue(SelectedOnboardingAction);
	const { getComplexPayload } = useComplexPublishAPI();

	const [details, setDetails] = useState<any>(null)
	const setUploadIsRequired = useSetRecoilState(uploadIsRequired)

	const { warningNotification, errorNotification } = useNotification();
	const { setNextStep } = useSetComplex();

	

	// pradeep chaurasia : this is use to check accreditationType is '506b' or not
	const checkAccreditationType = useMemo(
		() => complexSettingForm?.[id ?? '']?.accreditationType?.value === '506b',
		[complexSettingForm, id]
	);

	const isPayInSelected = useMemo(
		() => complexSettingForm?.[id]?.payInPayOut === 'payIn',
		[complexSettingForm, id]
	);

	const checkFundPayInType = useMemo(() => {
		// Check if unit price toggle is enabled for the given id
		const hasUnitPriceToggle = unitPriceToggleOn?.[id];

		// Check if the unit price input value for the given id is either empty or not a valid number
		const isUnitPriceEmptyOrInvalid =
			!unitPriceInputValue?.[id] ||
			!Number(unitPriceInputValue?.[id]) ||
			isNaN(Number(unitPriceInputValue?.[id]));
		// Return true if all conditions are met:
		return (
			isPayInSelected &&
			hasUnitPriceToggle &&
			isUnitPriceEmptyOrInvalid
		);
	}, [
		id,
		isPayInSelected,
		unitPriceInputValue,
		unitPriceToggleOn,
	]);


	const isValidURL = async (url: string): Promise<boolean> => {
		if (!REGEX.isValidUrl.test(url)) {
			return false;
		}

		let parsedUrl: URL;
		try {
			parsedUrl = new URL(url);
			if (parsedUrl.protocol !== 'http:' && parsedUrl.protocol !== 'https:') {
				return false;
			}
		} catch {
			return false;
		}

		return new Promise(resolve => {
			const img = new Image();

			img.src = `${parsedUrl.origin}/favicon.ico`;

			img.onload = () => resolve(true);
			img.onerror = () => resolve(false);
		});
	};
	  
	  
	const close = useCallback(async() => {
		if (
			// pradeep chaurasia : this is use to check accreditationType is '506b' or not
			checkAccreditationType &&
			addedFiles.type === 'customUpload' &&
			!addedFiles.configured
		) {
			if (addedFiles._id === '') {
				setUploadIsRequired(true);
				warningNotification('Please upload document.');
			} else {
				warningNotification('Please configure step.');
			}
		} else {
			const complexNodes: IComplexFlowUnconfiged[] = [];
			if (id) {
				if (ActiveActionKeyName === 'successScreenCompletion' || ActiveActionKeyName ==="successScreen") {
					const fields = complexSettingForm?.[id] ?? {};
					const {
						isAppStoreUrl,
						appStoreUrl,
						isPlayStoreUrl,
						playStoreUrl,
						isCustomBtnUrl,
						customRedirectUrl,
					} = fields;

					const validAppUrl = REGEX.isValidAppStoreUrl.test(appStoreUrl?.trim())
					const validPlayUrl = REGEX.isValidPlayStoreUrl.test(playStoreUrl?.trim())
					setCheckingUrl(true);
					const validCustomUrl = await isValidURL(customRedirectUrl?.trim())
					setCheckingUrl(false);

					if (
						isAppStoreUrl &&
						!validAppUrl
					) {
						setIsInputError(prev => ({ ...prev, appStoreUrl: true }));
						errorNotification('Invalid Appstore URL');
						return;
					}
					if (
						isPlayStoreUrl &&
						!validPlayUrl
					) {
						errorNotification('Invalid Playstore URL');
						setIsInputError(prev => ({
							...prev,
							playStoreUrl: true
						}));
						return;
					}
					if (
						isCustomBtnUrl &&
						!validCustomUrl
					) {
						setIsInputError(prev => ({
							...prev,
							customRedirectUrl: true
						}));
						errorNotification('Invalid Custom redirect button URL');
						return;
					}
				}
				if (checkFundPayInType) {
					setIsUnitPriceInputError(pre => ({ ...pre, [id]: true }));
					warningNotification('Please enter the unit price.');
					return;
				}
				if (ActiveActionKeyName !== 'dynamicForm') {
					setComplexFlowConfiged((prev: any) => {
						const newArr = structuredClone(prev);
						return newArr.map((el: any) => {
							if (el.id === id) {
								const value = {
									...el,
									configed: true,
								};
								complexNodes.push(value);
								return value;
							} else {
								complexNodes.push(el);
								return el;
							}
						});
					});
					if (complexConfigFromState === 'NEXT') {
						setNextStep(complexNodes);
					}
				}
			}
			if (complexConfigFromState === 'NEXT') {
				const pending = complexNodes.filter(node => !node.configed);
				if (!pending.length) {
					setIsOpen(false);
				}
			} else {
				setIsOpen(false);
			}
			setIsOpenModal(true);
			setUploadIsRequired(false);
		}
	}, [
		checkAccreditationType,
		addedFiles.type,
		complexSettingForm,
		addedFiles.configured,
		addedFiles._id,
		warningNotification,
		errorNotification,
		setUploadIsRequired,
		id,
		complexConfigFromState,
		setIsOpenModal,
		ActiveActionKeyName,
		setComplexFlowConfiged,
		setIsInputError,
		setNextStep,
		setIsOpen,
		checkFundPayInType,
		setIsUnitPriceInputError,
	]);

	// Arun kumar : Now we are showing instance number if there is same multiple node in onboarding flow
	const renderNodeInstance = useMemo(() => {
		const filteredItemsWithID = nodes?.filter(
			(item: { id: string }) => item?.id === id
		);
		if (filteredItemsWithID) {
			const filterItemwithLabel = nodes?.filter(
				(item: { label: string }) =>
					item.label === filteredItemsWithID[0]?.label
			);
			const index = filterItemwithLabel?.findIndex(
				(item: { id: string | undefined }) => item.id === id
			);
			if (filterItemwithLabel.length > 1 && navigate === 'complex-flow') {
				return `( Instance - ${index + 1} )`;
			} else {
				return <></>;
			}
		}
		return <></>;
	}, [id, navigate, nodes]);

	const handleDisable = useMemo(() => {
		if (key === 'accreditationVerify') {
			return settingsForm.accreditationType.value === '506b'
				? addedFiles.type === 'customUpload' && !addedFiles.configured
				: false;
		} else {
			return false;
		}
	}, [
		addedFiles.configured,
		addedFiles.type,
		settingsForm.accreditationType.value,
		key,
	]);

	useEffect(()=> {
		const { id, authId } = selectedOnboardingFlow;
		setDetails(()=> ({ ...getComplexPayload(authId), _id: id ? id : '123123' }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOnboardingFlow])

	const renderStepper = useMemo(()=> {
		if(!details) return
		return (
			<div className='ConfigureModal__stepper-container'>
				<SessionTreeGraph
					details={details}
					activeStepId={id}
					selectedStepsState={selectedStepsState}
					isStepper
				/>
				</div>
		)
	},[details, id, selectedStepsState])

	useEffect(() => {
		if (key !== 'accreditationVerify') return;

		if (!handleDisable) {
			if (
				!configuredStep.includes('accreditationVerify') &&
				unOpennedConfig.includes('accreditationVerify')
			) {
				const unOpenned = unOpennedConfig.filter(
					(actionKey: string) => actionKey !== 'accreditationVerify'
				);
				const opennedSteps = [...configuredStep];
				opennedSteps.push('accreditationVerify');
				setConfiguredStep(opennedSteps);
				setUnOpennedConfig(unOpenned);
			}
		} else {
			if (
				!unOpennedConfig.includes('accreditationVerify') &&
				configuredStep.includes('accreditationVerify')
			) {
				const unOpenned = [...unOpennedConfig];
				const opennedSteps = configuredStep.filter(
					(configuredStep: string) => configuredStep !== 'accreditationVerify'
				);
				unOpenned.push('accreditationVerify');
				setConfiguredStep(opennedSteps);
				setUnOpennedConfig(unOpenned);
			}
		}
	}, [
		unOpennedConfig,
		handleDisable,
		setConfiguredStep,
		setUnOpennedConfig,
		key,
		configuredStep,
	]);

	const handleGetMobileIntoView = useCallback(() => {
		mobileUiRef.current?.scrollIntoView(
			{
				behavior: "smooth",
				block: "center",
				inline: "center",
			  }
		);
	}, [])

	const header = useMemo(()=>{
		return (<div className="header__label">
		{label} {renderNodeInstance}
		<span onClick={handleGetMobileIntoView} className='header__lable--icon'>
			<i className="ri-eye-2-line"/>
			Preview
		</span>
	</div>)
	},[label, renderNodeInstance, handleGetMobileIntoView])

	return (
		<Modal
			isOpen={configType}
			closeModal={close}
			showCloseBtn={false}
			closeOnEscBtn={false}
			modalName="Configure"
			isStopOutsideClick={false}
			overlayStyles={{}}
			className={
				key === 'signAgreementVerification' ? `Onboarding-config-modal ${className}` : className
			}
			optionalClassName="Config-Modal--warpper"
		>
			<div>
				<PipelineBody isTitleVisible={false} renderStepper={renderStepper} header={header} mobileUiRef={mobileUiRef}/>
				<div
					className={kybKycClassName}
				>
					{/* kyb Verification Total cost */}
					{key === 'kybVerification' && (
						<div className="ConfigureModal--footers__prize">
							<div className="ConfigureModal--footers__prize__cost">
								<div>Total Cost (Fixed + Variable)</div>
								<div>
									<KybPriceTooltip kybPricing={kybStepPrice}/>
								</div>
							</div>
							<div className="ConfigureModal--footers__prize__total">
								${kybTotalPrice} + x * ${kybStepPrice?.kybSubsidiary ?? 0} + y * ${kybStepPrice?.representative_kyc_aml_verification ?? 0}
							</div>
						</div>
					)}
					{/* kyc Verification Total cost */}
					{key === 'allId' && (
						<div className="ConfigureModal--footers__prize">
							<div className="ConfigureModal--footers__prize__cost">
								Total Cost
							</div>
							<div className="ConfigureModal--footers__prize__total">
								${kycTotalPrice}
							</div>
						</div>
					)}
					<Button
						label={checkingUrl ? <Loader type="loader" dimension={20} /> : "Save & Close"}
						handleClick={close}
						type="button__filled button__filled--primary"
						disabled={checkingUrl}
					/>
				</div>
			</div>
		</Modal>
	);
};
