import {
	AutoFillLocation,
	Button,
	DateTimePicker,
	Input,
	IOption,
	Loader,
	ReactDropdown,
	TextArea,
} from '@storybook';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { Header } from 'views/header';
import Modal from '@storybook/new-modal/modal';
import { UploadDocs } from 'components';
import { convertFileToBase64 } from 'utils';
import { useInterval, useNotification } from 'hooks';
import { EventsList } from './components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loginState } from 'global-stores';
import { EVENTURL } from './constants';
import { EventsState, useEvents } from './store';
import './events.scss';

const initialEvents: IOption[] = [
    { value: 'both', label: 'Biometric and QR' },
    { value: 'any', label: 'Biometric or QR' },
    { value: 'biometric', label: 'Biometric' },
    { value: 'qr', label: 'QR' },
]

type IEventForm = {
	name: string;
	description: string;
	address: string;
	image: string;
	date: string;
	businessId?: string;
	type: {label: string; value: string};
};

export const Events = () => {
	const [isShowEventFormModal, setShowEventFormModal] = useState(false);
	const [apiLoading, setApiLoading] = useState(false);
	const [eventsState, setEventsState] = useRecoilState(EventsState);
	const userLogin = useRecoilValue(loginState);
	const [eventForm, setEventForm] = useState<IEventForm>({
		name: '',
		description: '',
		address: '',
		image: '',
		date: '',
		type: initialEvents[0],
	});
	const [isLoading, setLoading] = useState(false);

	const { initEvents } = useEvents();
	const { errorNotification, successNotification } = useNotification();

	const handleCreateEvent = () => {
		setShowEventFormModal(true);
	};

	const init = async () => {
		setLoading(true);
		await initEvents();
		setLoading(false);
	};

	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleResetForm = () => {
		setEventForm({
			name: '',
			description: '',
			address: '',
			image: '',
			date: '',
			type: initialEvents[0]
		});
	};

	const handleClose = useCallback(() => {
		setShowEventFormModal(false);
		handleResetForm();
	}, []);

	const handleOnChange = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setEventForm(prev => ({ ...prev, [name]: value }));
	};

	const onFileError = () => {
		return;
	};

	const handleFileChange = useCallback(async (event: File) => {
		const base64 = (await convertFileToBase64(event)) ?? '';
		if (!base64) {
			errorNotification('Something went wrong');
		}
		setEventForm(prev => ({ ...prev, image: base64 as string }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAutoInputValue = (data: any) => {
		setEventForm(prev => ({ ...prev, address: data?.enteredAddress }));
	};

	const handleChangeSelect = (option: IOption,name:string) => {
		setEventForm(prev => ({ ...prev, [name]: option }));
	};

	useInterval(() => {
		initEvents();
	}, 3000);

	const onCreateEvent = useCallback(async () => {
		const isDuplicate = eventsState?.some(el => el.name === eventForm.name);
		if (isDuplicate) {
			errorNotification('Duplicate event name detected');
			return;
		}

		setApiLoading(true);
		const { type,...rest } = eventForm;
		const payload= {
			...rest,
			type:type.value,
			businessId: userLogin.business,
		};
		try {
			const resp = await fetch(`${EVENTURL}/events`, {
				method: 'POST',
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json',
				},
			});
			const data = await resp.json();

				if (data?.message === 'ok') {
					const { data: result } = data;
					setEventsState(prev => [result, ...prev]);
					successNotification('Event created successfully');
					handleClose();
				}
			} catch (error) {
				errorNotification('Failed to create event. Please try again.');
			} finally {
				setApiLoading(false);
			}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorNotification, eventForm, setEventsState, userLogin.business]);

	const handleRemoveImage = () => {
		setEventForm(prev => ({ ...prev, image: '' }));
	};

	const btnDisable = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const {type,...rest}=eventForm
		const isValid = Object.values(rest).every(v => v?.length);
		return isValid;
	}, [eventForm]);

	return (
		<div className="Events">
			<Header
				title="Events"
				showBtn
				btnLabel="Create New"
				handleClickBtn={handleCreateEvent}
			/>
			<div className="Events--list">
				<EventsList apiLoading={isLoading} />
			</div>

			{isShowEventFormModal && (
				<Modal
					isOpen={isShowEventFormModal}
					modalName="Events"
					closeModal={handleClose}
					className="Events-Modal"
					showCloseBtn
					isStopOutsideClick={false}
					optionalClassName="Events--Modal-body"
				>
					<div className="Events--header">
						<h2>Create New Event</h2>
					</div>
					<form className="Events--form" onSubmit={onCreateEvent}>
						<ReactDropdown
							label="Type"
							options={initialEvents}
							value={eventForm.type}
							handleChangeSelect={(option:IOption)=>handleChangeSelect(option,'type')}
						/>
						<Input
							label="Event Name"
							inputType="text"
							placeholder="Event Name"
							maxLength={100}
							inputName="name"
							handleChange={handleOnChange}
							value={eventForm.name}
							isRequired
							key="name"
						/>
						<AutoFillLocation
							label="Address"
							placeHolder="Address"
							isRequired
							handleOnChange={handleOnChange}
							value={eventForm.address}
							inputName="address"
							onSuccess={handleAutoInputValue}
							key="address"
						/>
						<DateTimePicker
							handleChange={handleOnChange}
							inputName="date"
							placeholder="Date & Time"
							value={eventForm.date}
							min={new Date().toISOString().slice(0, 16)}
						/>
						<TextArea
							label="Description"
							handleChange={handleOnChange}
							inputName="description"
							placeholder="Description"
							value={eventForm.description}
							key="description"
						/>
						<UploadDocs
							label="Image"
							handleChange={handleFileChange}
							isShowProgess
							accept=".png, .jpg, .jpeg"
							supportedFileDescription=".png, .jpg, .jpeg"
							onError={onFileError}
							key="image"
							uplaodedFile={eventForm?.image}
							handleRemove={handleRemoveImage}
						/>
					</form>
					<div className="Events--footer">
						<Button
							label="Cancel"
							type="button__filled button__filled--secondary"
							handleClick={handleClose}
						/>
						<Button
							label={
								!apiLoading ? 'Create' : <Loader dimension={20} type="loader" />
							}
							type="button__filled button__filled--primary"
							handleClick={onCreateEvent}
							disabled={!btnDisable || apiLoading}
						/>
					</div>
				</Modal>
			)}
		</div>
	);
};
