/* eslint-disable react/no-array-index-key */
import { Confirmation, ReactSwitch, Tooltip } from '@storybook';
import {
	FC,
	Fragment,
	JSXElementConstructor,
	Key,
	ReactElement,
	ReactNode,
	ReactPortal,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
	EmailMessageReminderValue,
	IAction,
	IsPipelineFlowErrorMessage,
	PipelineMasterSteps,
	PipelineSettingsFormState,
	isChecqueFraudSelected,
} from 'global-stores';
import {
	ComplexFlowUnconfigedState,
	EdgesState,
	NodesState,
} from 'views/complex-onboarding-flow';
import {
	AddedActionsState,
	CurrentStepState,
	useOnboardingHook,
} from 'views/onboarding-flow';
import { usePipelineData } from 'views/pipelines';
import { CHEQUE_FRAUD_CONFIRMATION_MODAL } from '../constants';
import { ComplexSettingFormState, UnitPriceToggleState } from '../store';
import { RadioSelection } from './radio-selection';
import { IMetaData, IOfacSubChild } from './type';

interface IToggleComponent {
	label: string;
	description: string;
	name: string;
	id?: string;
	price?: number;
	isEmailToggle?: boolean;
	isMessageToggle?: boolean;
	kybOfacChildData?: any;
}

const successObj: any = {
	isAppStoreUrl: 'appStoreUrl',
	isPlayStoreUrl: 'playStoreUrl',
	isCustomBtnUrl: 'customRedirectUrl',
};

  
export const ToggleComponent: FC<IToggleComponent> = ({
	label,
	description,
	name,
	id,
	price,
	isEmailToggle,
	isMessageToggle,
	kybOfacChildData,
}) => {
	const [kycPrice, setKycPrice] = useState({
		liveness: 0,
		withoutLiveness: 0,
	});
	const [isChequeFraudModal, setIsChequeFraudModal] = useState(false);
	const { key } = useRecoilValue<IAction>(CurrentStepState);
	const setEdges = useSetRecoilState(EdgesState);
	const nodes = useRecoilValue(NodesState);

	const [settingsForm, setSettingsForm] = useRecoilState(
		PipelineSettingsFormState
	);
	const masterSteps = useRecoilValue(PipelineMasterSteps);

	const setAddedActions = useSetRecoilState(AddedActionsState);

	const [complexSettingForm, setComplexSettingForm] = useRecoilState(
		ComplexSettingFormState
	);

	const [emailMessageReminderValue, setEmailMessageReminderValue] =
		useRecoilState(EmailMessageReminderValue);

	const { handleChequeFraudSelection } = usePipelineData();
	const setIsChequeFraudSelected = useSetRecoilState(isChecqueFraudSelected);
	const setComplexFlowConfiged = useSetRecoilState(ComplexFlowUnconfigedState);
	const setIsInputError = useSetRecoilState(IsPipelineFlowErrorMessage);
	const [unitPriceToggle, setUnitPriceToggle] =
		useRecoilState(UnitPriceToggleState);

	const { isPricingHide } = useOnboardingHook();

	const { TITLE, DESCRIPTION, LABEL, SECONDRYL_LABEL } =
		CHEQUE_FRAUD_CONFIRMATION_MODAL;

	const isUnitPriceToggle = useMemo(() => name === 'payInUnitPricing', [name]);
	const isPayInSelected = useMemo(
		() => complexSettingForm?.[id ?? '']?.payInPayOut === 'payIn',
		[complexSettingForm, id]
	);

	useEffect(() => {
		//  we are doing this only for the case only for kyc
		//  and will not be function for other toggle steps if got merged in future
		let foundMasterAction: any = {};
		masterSteps.forEach(step => {
			return step.actions.forEach(action => {
				if (action.key === 'allId') {
					foundMasterAction = action;
				}
			});
		});
		const livenessPrice = foundMasterAction?.price ?? 0;
		const withoutLivenessPrice = foundMasterAction?.metadata?.[0]?.price ?? 0;
		setKycPrice({
			liveness: livenessPrice,
			withoutLiveness: withoutLivenessPrice,
		});

		// eslint-disable-next-line
	}, []);

	// Settings default values for complex flow config
	useEffect(() => {
		if (id && !complexSettingForm?.[id]) {
			const payload = {
				[name]: settingsForm[name],
				// @avinashSatschel: The name is 'addressVerification' and 'ssn' should be set in the state since we have merged 'ssn' with 'addressVerification'.
				...(name === 'addressVerification' && {
					ssn: settingsForm[name],
				}),
			};
			setComplexSettingForm((prev: any) => {
				const newObj = structuredClone(prev);
				if (!newObj[id]) {
					newObj[id] = {};
				}
				Object.assign(newObj[id], payload);
				return newObj;
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, name, complexSettingForm, setComplexSettingForm]);

	// Paras: handle auto close email and message toggle reminder section
	const enableReminderSettingValue = useCallback(() => {
		const { emailInput, messageInput } = emailMessageReminderValue;
		if (!messageInput && !emailInput) {
			setSettingsForm(prev => {
				const prevState = { ...prev };
				prevState.enableReminderSetting = false;
				return { ...prevState };
			});
			setEmailMessageReminderValue({ messageInput: true, emailInput: true });
			return;
		}
		if (!settingsForm.enableReminderSetting && (!emailInput || !messageInput)) {
			setEmailMessageReminderValue({ messageInput: true, emailInput: true });
		}
	}, [
		emailMessageReminderValue,
		setSettingsForm,
		setEmailMessageReminderValue,
		settingsForm,
	]);

	useEffect(() => {
		enableReminderSettingValue();
	}, [enableReminderSettingValue, settingsForm]);

	const handleToggleChange = useCallback(
		(value: boolean) => {
			/*
				@avinashSatschel
				If the 'name' is 'chequeFraud', check the value to determine the appropriate action.
				If 'value' is falsy, set 'isChequeFraudSelected' to false without returning,
				as a falsy value implies deactivation of Cheque Fraud.

				If 'value' is truthy, set 'isChequeFraudModal' to true and return immediately.
				The complete handling of Cheque Fraud selection is managed in the 'handleChequeFraudSelection' method.
			*/
			if (name === 'chequeFraud') {
				if (!value) {
					setIsChequeFraudSelected(false); // Set to false for deactivation.
				} else {
					setIsChequeFraudModal(true); // Set to true for activation.
					return; // Return to prevent further execution, as the handling is managed elsewhere.
				}
			}

			if (isUnitPriceToggle) {
				setUnitPriceToggle(pre => ({ ...pre, [id ?? '']: value }));
				return;
			}

			if (key === 'kybVerification') {
				if(name !== 'business_sanctions_ofac'){
				setSettingsForm(prev => {
					const prevState = { ...prev };
					prevState[name] = value;
					return { ...prevState };
				});
			}

				
				// If kyb comprehensive toggle off then automatically kybSubsidiary will also OFF
				if (name === 'kyb_comprehensive' && !value) {
					if (id) {
						setComplexSettingForm((prevState: { [x: string]: any }) => ({
							...prevState,
							[id]: {
								...prevState[id],
								kybSubsidiary: value,
							},
						}));
					}
				}
				if (name === 'business_sanctions_ofac') {
					if (value && id) {
						setComplexSettingForm((prevState: { [x: string]: any }) => ({
							...prevState,
							[id]: {
								...prevState[id],
								business_sanctions_ofac : value,
								amlFrequency: 'oneTime',

							},
						}));
					} else if (!value && id) {
						setComplexSettingForm((prevState: { [x: string]: any }) => {
							const updatedState = { ...prevState };
					
							// Check if the `id` exists in `updatedState`
							if (updatedState[id] && updatedState[id].amlFrequency !== "") {
								
								// Suppress warning by renaming amlFrequency to _
								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								const { amlFrequency: _, ...rest } = updatedState[id];
								updatedState[id] = rest;
							}
					
							return updatedState;
						});
					}}
			}

			if (key === 'successScreenCompletion') {
				setSettingsForm(prev => {
					const prevState = { ...prev };
					prevState[name] = value;
					return { ...prevState };
				});
				setIsInputError(prev => ({ ...prev, [successObj[name]]: false }));
			}

			if (name === 'enableReminderSetting' && !id) {
				setSettingsForm(prev => {
					const prevState = { ...prev };
					prevState[name] = value;
					return { ...prevState };
				});
				return;
			}

			//we have created another state as there could be multiple kyc aml
			if (id) {
				const payload = {
					[name]: value,
					// @avinashSatschel: The name is 'addressVerification' and 'ssn' should be set in the state since we have merged 'ssn' with 'addressVerification'.
					...(name === 'addressVerification' && {
						ssn: value,
					}),
				};

				setComplexSettingForm((prev: any) => {
					const newObj = structuredClone(prev);
					if (!newObj[id]) {
						newObj[id] = {};
					}
					Object.assign(newObj[id], payload);
					return newObj;
				});
			}
			// this has been added in the flow just for the kyc liveness check
			// for any other specific condition please add bellow
			if (name === 'liveliness') {
				setAddedActions(prev => {
					const prevState = JSON.parse(JSON.stringify(prev));
					const foundIndex = prevState.findIndex(
						({ key }: any) => key === 'allId'
					);
					prevState[foundIndex].price =
						value === true ? kycPrice.liveness : kycPrice.withoutLiveness;
					return prevState;
				});
			}
		},
		[
			name,
			isUnitPriceToggle,
			key,
			id,
			setIsChequeFraudSelected,
			setUnitPriceToggle,
			setSettingsForm,
			setComplexSettingForm,
			setIsInputError,
			setAddedActions,
			kycPrice.liveness,
			kycPrice.withoutLiveness,
		]
	);

	const isJSONString = useCallback((data: any) => {
		// In here we check is description contain JSON string or normal string
		try {
			JSON.parse(data);
			return true;
		} catch (e) {
			return false;
		}
	}, []);

	const isSubsidiaryToggle = useMemo(() => {
		return (
			settingsForm?.kyb_comprehensive ||
			complexSettingForm[id ?? '']?.['kyb_comprehensive']
		);
	}, [complexSettingForm, id, settingsForm?.kyb_comprehensive]);

	const renderDescription = useMemo(() => {
		if (name === 'liveliness') {
			// return `${description} (+ $${
			//   kycPrice.liveness - kycPrice.withoutLiveness
			// }/verification)`;
		}
		// paras: when "kyb_comprehensive" toggle is ON only after that return description of "kybSubsidiary"
		if (name === 'kybSubsidiary' && !isSubsidiaryToggle) {
			return;
		}
		// Arun kumar : despcrtion of KYB , we are getting in form of JSON.stringfy
		if (description.length > 0 && isJSONString(description)) {
			const kybDesp = JSON.parse(description as any);
			return (
				<ul className="pipeline-settings__bullet-points">
					{kybDesp.map(
						(
							item:
								| string
								| number
								| boolean
								| ReactElement<any, string | JSXElementConstructor<any>>
								| Iterable<ReactNode>
								| ReactPortal
								| null
								| undefined
						) => (
							<div
								key={item?.toString()}
								className="pipeline-settings__bullet-points-list"
							>
								<i className="ri-checkbox-circle-line"></i>
								<li>{item}</li>
							</div>
						)
					)}
				</ul>
			);
		} else {
			return description;
		}
	}, [description, isJSONString, name, isSubsidiaryToggle]);

	const initialValue = useMemo(() => {
		return name === 'enableReminderSetting' && !id
			? settingsForm[name]
			: complexSettingForm[id ?? '']?.[name];
	}, [complexSettingForm, id, name, settingsForm]);

	const isDisable = useMemo(() => {
		if (name === 'idv') {
			return true;
		} else if (name === 'kyb_basic') {
			return true;
		} else if (name === 'kyb_ein') {
			return true;
		} else if (/faceEnrollment|palmEnrollment/.test(name)) {
			return true;
		} else if (name === 'kyb_enhanced') {
			return true;
		}
		if (name === 'kyb_flags') {
			return true;
		}
		if (
			name === 'dun-brad-street-section' &&
			!complexSettingForm[id ?? '']?.kyb_comprehensive
		) {
			return true;
		}
		return false;
	}, [complexSettingForm, id, name]);
	// Paras: onchange handle for email and message switch
	const handleEmailMessageToggle = useCallback(
		(value: any, label: any) => {
			if (label === 'Email') {
				setEmailMessageReminderValue({
					...emailMessageReminderValue,
					emailInput: value,
				});
				return;
			} else {
				setEmailMessageReminderValue({
					...emailMessageReminderValue,
					messageInput: value,
				});
			}
		},
		[setEmailMessageReminderValue, emailMessageReminderValue]
	);

	/*
		@avinashSatschel
		Handle the Cheque Fraud modal based on the provided parameters.
		If 'isSubmit' is true, determine the 'type' based on whether a complex or linear flow is selected,
		then invoke 'handleChequeFraudSelection' method with the 'type' and optional 'id'.
		If 'id' is not provided, it defaults to an empty string.
		If 'isSubmit' is false, invoke 'handleToggleChange' with 'false' to handle toggle changes.
		Regardless of 'isSubmit', always close the Cheque Fraud modal by setting 'isChequeFraudModal' to false.
	*/

	const handleCheckFraudModal = (_isOpen: boolean, isSubmit: boolean) => {
		if (isSubmit) {
			const type = 'complex';

			const authId = nodes.find((el: any) => el.key === 'authentication')?.id;
			handleChequeFraudSelection(type, id ?? ''); // Invoke Cheque Fraud selection handling.
			setComplexFlowConfiged(prev => {
				const newObj = structuredClone(prev);
				return newObj.filter(el => el.id === id || el.id === authId);
			});

			if (id) {
				const defaultEdges = {
					source: authId,
					sourceHandle: authId,
					target: id,
					targetHandle: id,
					type: 'buttonedge',
					markerEnd: {
						type: 'arrow',
						width: 10,
						height: 10,
						color: '#b3b3b3',
					},
					style: {
						strokeWidth: 3,
						stroke: '#b3b3b3',
						zIndex: 999,
					},
					id: `reactflow__edge-${authId + authId}-${id + id}`,
				};
				setEdges([defaultEdges]);
			}
		} else {
			handleToggleChange(false); // Handle toggle change when not submitting.
		}
		setIsChequeFraudModal(false); // Close the Cheque Fraud modal.
	};

	const getConfigurationLabel = useMemo(() => {
		if (name !== 'representative_kyc_aml_verification') {
			return '/verification';
		} else {
			return '/Representative verification';
		}
	}, [name]);

	const renderSubsideryNote = useMemo(() => {
		return (
			<div className="pipeline-settings__subsidiary-notes__wrapper">
				<div className="pipeline-settings__subsidiary-notes__header">
					<span className="pipeline-settings__subsidiary-notes__title">
						KYB for Subsidiaries
					</span>
					<span>
						Additional(
						<span className="pipeline-settings__subsidiary-notes__price">
							$5.50
						</span>
						/Verification per subsidiary)
					</span>
				</div>
				<div className="pipeline-settings__subsidiary-notes__description">
					<i className="pipeline-settings__subsidiary-notes__alert-icon ri-alert-fill" />
					<span className="pipeline-settings__subsidiary-notes__description__text">
						KYB for Subsidiaries can only be selected if KYB Comprehensive is
						chosen. KYB for Subsidiaries includes KYB Enhanced and OFAC
						Sanctions report.
					</span>
				</div>
			</div>
		);
	}, []);

	const renderHeader = useMemo(() => {
		// rendering subsidiary note only when "kyb_comprehensive" toggle is not ON
		if (name === 'kybSubsidiary' && !isSubsidiaryToggle) {
			return renderSubsideryNote;
		}
		return (
			<>
				<div className="pipeline-settings__actions-title">
					{label}
					{name !== 'enableReminderSetting' ||
						((isEmailToggle || isMessageToggle) && (
							<span className="pipeline-settings__pricing">
								({`$${(price ?? 0).toFixed(2)}/ Verification`})
							</span>
						))}
					{isPricingHide && (
						<span className="pipeline-settings__pricing-kyb">
							{`${'('}`}
							<span className="pipeline-settings__pricing-kyb-price">
								{`$${(price ?? 0).toFixed(2)}`}
							</span>
							{getConfigurationLabel}
							{`${')'}`}
						</span>
					)}
				</div>
				{name === 'palmEnrollment' ? (
					<div className="pipeline-settings__coming-soon">Coming soon</div>
				) : (
					<Tooltip
						text={
							isUnitPriceToggle
								? "The unit price can only be enabled when the 'Fund PayIn' option is selected."
								: 'Dun & Bradstreet can only be selected if KYB Comprehensive is chosen'
						}
						customStyle={{
							display:
								(name === 'dun-brad-street-section' &&
									!complexSettingForm[id ?? '']?.kyb_comprehensive) ||
								(isUnitPriceToggle && !isPayInSelected)
									? 'block'
									: 'none',
						}}
						direction={isUnitPriceToggle ? 'left' : 'top'}
					>
						<ReactSwitch
							// Paras: add intial value and onchange handle for email and message reminder switch
							checked={
								isUnitPriceToggle
									? unitPriceToggle?.[id ?? '']
									: isEmailToggle
									? emailMessageReminderValue.emailInput
									: isMessageToggle
									? emailMessageReminderValue.messageInput
									: initialValue
							}
							handleChange={
								isEmailToggle || isMessageToggle
									? e => handleEmailMessageToggle(e, label)
									: handleToggleChange
							}
							id=""
							onColor="#33b87a"
							isDisabled={isDisable}
						/>
					</Tooltip>
				)}
				
			</>
		);
	}, [
		complexSettingForm,
		emailMessageReminderValue.emailInput,
		emailMessageReminderValue.messageInput,
		getConfigurationLabel,
		handleEmailMessageToggle,
		handleToggleChange,
		id,
		initialValue,
		isDisable,
		isEmailToggle,
		isMessageToggle,
		isPricingHide,
		label,
		name,
		price,
		renderSubsideryNote,
		isSubsidiaryToggle,
		isUnitPriceToggle,
		unitPriceToggle,
		isPayInSelected,
	]);

		const renderOfacChildData = useMemo(() => {
			if (!kybOfacChildData) return null;

			return (kybOfacChildData ?? []).map(
				({ children }: IMetaData, parentIndex: Key) => (
					<Fragment key={parentIndex}>
						{(children ?? []).map(
							(child: IOfacSubChild, childIndex: number) => (
								<RadioSelection
									key={child?.key || childIndex}
									stepKey={name}
									actionKey={child?.key}
									data={child?.options ?? []}
									defaultValue={complexSettingForm?.[id ?? '']?.amlFrequency}
									isDisable={!complexSettingForm?.[id ?? '']?.business_sanctions_ofac}
									id={id}
									isPriceHide={true}
								/>
							)
						)}
					</Fragment>
				)
			);
		}, [complexSettingForm, id, kybOfacChildData, name]);

	return (
		<>
			<div className="pipeline-settings__actions-header">{renderHeader}</div>
			<div className="pipeline-settings__actions-description pipeline-settings__desp-width">
				{renderDescription}
			</div>
			{name === 'business_sanctions_ofac' && (
				<div className="pipeline-settings__action-child-wrapper">
					{renderOfacChildData}
				</div>
			)}

			<Confirmation
				visible={isChequeFraudModal}
				title={TITLE}
				description={
					<div>
						{DESCRIPTION}
						<span className="fw-600">Would you like to proceed?</span>
					</div>
				}
				handleModal={handleCheckFraudModal}
				label={LABEL}
				secondryLabel={SECONDRYL_LABEL}
			/>
		</>
	);
};
