import { FC, MouseEvent } from 'react';
import { IEventsState } from 'views/events/store';
import { useNotification } from 'hooks';
import { getDateTime } from 'utils';
import { Button } from '@storybook';
import './event-card.scss';

interface IEventCard {
	handleOpen: (id: string) => void;
	openQrModal: (e: MouseEvent<HTMLElement>,id:string) => void;
}
export const EventCard: FC<IEventsState & IEventCard> = ({
	name,
	description,
	image,
	date,
	address,
	_id,
	handleOpen,
	users,
	openQrModal,
}) => {
	const { successNotification } = useNotification();

	const onCopyText = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		navigator.clipboard
			.writeText(_id)
			.then(() => {
				successNotification('Copy clipboard');
			})
			.catch(err => {
				// eslint-disable-next-line no-console
				console.error('Failed to copy text: ', err);
			});
	};

	const handleClickOpenLink = (e: MouseEvent<HTMLElement>, type: string) => {
		e.stopPropagation();
		window.open(
			`https://liveness.onyxplus.app/${type}?eventId=${_id}`,
			'_blank',
			'noopener,noreferrer'
		);
	};

	return (
		<section className="EventCard" onClick={() => handleOpen(_id)}>
			<div className="EventCard--img">
				<img src={image} alt={name} />
			</div>
			<div className="EventCard--info">
				<div className="EventCard--id">
					<div className='EventCard--id--box'>
						<p onClick={e => onCopyText(e)}>{_id}</p>
						<div className="EventCard--copy" onClick={e => onCopyText(e)}>
							<i className="ri-file-copy-line"></i>
						</div>
					</div>
					<div className="EventCard--link">
					<Button
						label={<i style={{fontSize: "25px"}} className="ri-qr-code-line" />}
						handleClick={(e)=>openQrModal(e,_id)}
						type="menu-item actions-wrapper__hover"
					/>
					</div>
				</div>
				<div className='EventCard--link-qr'>
					<div className="EventCard--link">
						<a
							onClick={e => handleClickOpenLink(e, "events")}
							href={`https://liveness.onyxplus.app/events?eventId=${_id}`}
							rel="noreferrer"
							target="_blank"
						>
							Event Link<i className="ri-external-link-line"></i>
						</a>
					</div>
					<div className="EventCard--link">
						<a
							onClick={e => handleClickOpenLink(e, "drone")}
							href={`https://liveness.onyxplus.app/drone?eventId=${_id}`}
							rel="noreferrer"
							target="_blank"
						>
							Drone Link<i className="ri-external-link-line"></i>
						</a>
					</div>
				</div>
				<div className="EventCard--name">
					<p>{name}</p>
				</div>
				<div className="EventCard--date">
					<p>{getDateTime(date)}</p>
				</div>
				<div className="EventCard--address">
					<p>{address}</p>
				</div>
				<div className="EventCard--users">
					<i className="ri-user-line"></i>
					<p>{users?.length}</p>
					<span>users</span>
				</div>
				<div className="EventCard--desc">
					<p>{description}</p>
				</div>
			</div>
		</section>
	);
};
