/* eslint-disable no-console */
import axios from 'axios';
import Tippy from '@tippyjs/react';
import { onValue, ref } from 'firebase/database';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import {
	Button,
	IconBtn,
	Loader,
	ReactResponsiveTable,
	TextForFilterSessionsState,
	useSessionsTextFilter,
} from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { defineds } from '@storybook/date-range-picker/defineds';

import { API_URL, MESSAGE, message } from 'constant';
import {
	BCcEmailState,
	CcEmailState,
	ComplexSelectedSession,
	ComplexSelectedSessionAssigneeIDState,
	ComplexSessionInfoState,
	ComplexSessionNodesState,
	ComplexSessionState,
	ConditionalSessionFlowState,
	FilterSessionTypeTableState,
	FilterTableIdState,
	FundPaginationState,
	FundsState,
	FundsToShowState,
	IShowFunds,
	SelectedDateRangeState,
	SelectedSessionState,
	SelectedSessionsIdState,
	SelectedSessionsIdsForZipState,
	SessionItemSelected,
	SessionState,
	SessionTableRow,
	TotalUnviewedCountState,
	checkBoxStateInTable,
	filteringTagsState,
	loginState,
} from 'global-stores';
import { ICredential } from 'global-stores/sessions/type';
import { useCheckOldUserValid } from 'helpers';
import {
	INIT_CONFIGURATION_SAVE,
	useFirebaseInit,
	useFreePlan,
	useIndexedDB,
	useInterval,
	useMarketing,
	useNetwork,
	useNotification,
} from 'hooks';
import 'tippy.js/dist/tippy.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';
import { getDate, getOffDate, getTime, useFormatNumber } from 'utils';
import {
	OnboardingTreeGraph,
	SessionTreeGraph,
} from 'views/complex-onboarding-flow';
import { fundsColumn } from 'views/funds';
import { Header } from 'views/header';
import { actionKeyLabel } from 'views/pipelines';
import { ComplexStepsView } from 'views/pipelines/components/complex-step-view';
import { IFilterTabStatus, SelectedFilterTabState } from '@storybook/react-responsive-table/store';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import {
	ArchiveConfirmationModal,
	AssignSelectedSessionState,
	ConfigureSession,
	dropDownSelectedReviewerState,
	ExportPdf,
	recallComplexSessionDataState,
	ReInviteKyc,
	SessionEsignLink,
	ViewKyc,
} from './components';
import { ColorLegends } from './components/color-legends';
import { ComplexSessionView } from './components/complex-session-view';
import { DropOptions } from './components/dropOptions/dropOptions';
import { SelectedFundModal } from './components/select-fund-modal/select-fund-modal';
import {
	SessionStepStatus,
	SessionTable,
	SessionTypesQuery,
} from './constants';
import { debounce } from 'utils/debounce';
import { useComplexSessionInfo, useSessionDetails } from './hooks';
import { Memory } from './store/memory';

import './session.scss';
import {
	InboxSignModal,
	useSignedDocsInbox,
} from 'views/signed-doc-inbox';
import { REACT_APP_ARQ_SECURITIES_BUSINESSID, REACT_APP_PSB_BUSINESSID } from 'envs';
import { AssignReviewerModal } from './components/assign-reviewer-modal/assign-reviewer-modal';
import { AssigneeSessionState, IsAssigneeSessionLoadingState } from './store';
import { toast } from 'react-toastify';
import { SessionSendSynergy } from './components/session-send-synergy';

interface IOptionsRow {
	action: () => void;
	createdAt: string;
	name: string;
	_id: string;
}

const parseUrl = (data: Record<string, string[]>) => {
	let queryString = '';
	const actionsStatuses = ['pending', 'processing', 'completed', 'rejected'];
	const foundAndAccreditationActions = ['506b', '506c', 'payIn', 'payOut'];

	const actions: Array<{ stepId: string; status: string[]; subType?: string }> =
		[];
	let pipelinesNames: string[] = [];
	let fundNames: string[] = [];
	let assigneeNames: string[] = []

	Object.keys(data).forEach(key => {
		if (data[key]?.some(value => actionsStatuses.includes(value))) {
			if (foundAndAccreditationActions.includes(key)) {
				const step = {
					stepId:
						key === '506b' || key === '506c'
							? 'accreditation'
							: 'fundInvestment',
					status: data[key] ?? [],
					subType: key,
				};
				actions.push(step);
			} else {
				const step = {
					stepId: key,
					status: data[key] ?? [],
				};
				actions.push(step);
			}
		}
		if (key === 'assignedUserIds') {
			assigneeNames = data[key] ?? [];
		}
		if (key === 'fund_name') {
			fundNames = data[key] ?? [];
		}
		if (key === 'onboardingFlowName') {
			pipelinesNames = data[key] ?? [];
		}
	});

	queryString =
		actions.length > 0
			? (queryString += `includeSteps=${JSON.stringify(actions)}&`)
			: queryString;

	fundNames.forEach(name => {
		queryString += `fundName[]=${encodeURIComponent(name)}&`;
	});
	assigneeNames.forEach(id => {
		queryString += `assignedUserIds[]=${encodeURIComponent(id)}&`;
	});
	pipelinesNames.forEach(name => {
		queryString += `pipelineName[]=${encodeURIComponent(name)}&`;
	});

	// Remove trailing '&' if necessary
	if (queryString.endsWith('&')) {
		queryString = queryString.slice(0, -1);
	}

	return queryString;
};

const pageLimitComplex = 10;

export const Sessions: FC = () => {
	const [openSelectedFund, setOpenSelectedFund] = useState(false);
	const [openAssignReviewer, setOpenAssignReviewer] = useState(false);
	const [openLinkEsignModal, setOpenLinkEsignModal] = useState(false);
	const [openSendSynergyModal, setOpenSendSynergyModal] = useState(false);
	const [collapseSessionOpenId, setCollapseSessionOpenId] = useState<any>([]);
	const setDropDownSelectedReviewer = useSetRecoilState(dropDownSelectedReviewerState);
	const [recallComplexSessionData,setRecallComplexSessionData] = useRecoilState(recallComplexSessionDataState);
	const resetComplexSelectedSessionAssigneeID = useResetRecoilState(ComplexSelectedSessionAssigneeIDState);
	const resetAssigneeSessionLoading = useResetRecoilState(IsAssigneeSessionLoadingState);
	const resetSessionAssignee = useResetRecoilState(AssigneeSessionState);
	const [openConfigureSession, setOpenConfigureSession] = useState(false);
	const [sessionList, setSessionList] = useRecoilState(SessionState);
	const [complexSessionList, setComplexSessionList] =
		useRecoilState(ComplexSessionState);
	const setTotalUnviewedCount = useSetRecoilState(TotalUnviewedCountState);
	const setComplexSessionNodes = useSetRecoilState(ComplexSessionNodesState);
	const setComplexSelectedSession = useSetRecoilState(ComplexSelectedSession);
	const user = useRecoilValue(loginState);

	const [sessionListRows, setSessionListRows] = useRecoilState(SessionTableRow);
	const setSessionItemSelected = useSetRecoilState(SessionItemSelected);
	const resetCcEmails = useResetRecoilState(CcEmailState);
	const resetBCcEmails = useResetRecoilState(BCcEmailState);
	const [selectedDateRange, setSelectedDateRange] = useRecoilState(
		SelectedDateRangeState
	);
	const filterTableId = useRecoilValue(FilterTableIdState);
	const [selectedIdState, setSelectedIdState] = useRecoilState(
		SelectedSessionsIdState
	);
	const filterActiveTab: IFilterTabStatus = useRecoilValue(SelectedFilterTabState);

	const [selectedIdsForZip, setSelectedIdsForZip] = useRecoilState(
		SelectedSessionsIdsForZipState
	);
	const [selectedSession, setSelectedSession] = useRecoilState(
		AssignSelectedSessionState
	);
	const location = useLocation();

	const resetSessionSelected = useResetRecoilState(SelectedSessionState);
	const resetComplexSessionInfo = useResetRecoilState(ComplexSessionInfoState);

	const pageNumber = useRecoilValue(FundPaginationState);
	const restPageNumber = useResetRecoilState(FundPaginationState);
	const [pagecount, setPageCount] = useState(0);

	const setCheckBoxState = useSetRecoilState(checkBoxStateInTable);
	const [sessionTypeState, setSessionTypeState] = useRecoilState(
		FilterSessionTypeTableState
	);
	const setFundsRow = useSetRecoilState(FundsToShowState);
	const funds = useRecoilValue(FundsState);
	const { numberDecimal } = useFormatNumber();
	const { checkBefore2Oct23Date } = useCheckOldUserValid();

	const [signPrepareUrl, setSignPrepareUrl] = useState<{
		env: string;
		url: string;
	}>({
		env: '',
		url: '',
	});
	const [showSignModal, setShowSignModal] = useState<boolean>(false);
	const [signLoader, setSignLoader] = useState<boolean | string>(false);
	const [openView, setOpenView] = useState<boolean>(false);
	const [complexListLoading, setComplexListLoading] = useState<boolean>(false);
	const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
	const [kycReinviteModal, setKycReinviteModal] = useState<boolean>(false);
	const [reinviteActiveStep, setReinviteActiveStep] = useState({});
	const [isArchiveModal, setIsArchiveModal] = useState<boolean>(false);
	const [selectedSessionId, setSelectedSessionId] = useState<string>('');
	const [selectedReInvite, setSelectedReInvite] = useState<string>('');
	const [complexOpenView, setComplexOpenView] = useState<boolean>(false);
	const [complexStepView, setComplexStepView] = useState<any>({});
	const [selectedLinkSession, setSelectedLinkSession] = useState<any>({});
	const [isComplexTab, setIsComplexTab] = useRecoilState(
		ConditionalSessionFlowState
	);
	const filterTextSearch = useRecoilValue(TextForFilterSessionsState);
	const userDetails = useRecoilValue(loginState);
	const [canceling, setCanceling] = useState(false);

	// Hooks
	const { signDocInbox, getSignedDocsInbox } = useSignedDocsInbox();
	const { remove: archiveSession, loading: archiveLoading } = useNetwork();
	const { successNotification, errorNotification } = useNotification();
	const {
		get: getSessionList,
		patch,
		isLoaded: sessionListLoaded,
		data: sessionListLinearData,
	} = useNetwork({ updateState: true });
	const { get: getComplexSessionList, isLoaded: complexSessionListLoaded } =
		useNetwork({ updateState: false });

	const { complexSessionInfo, complexSessionDetailsLoading } =
		useComplexSessionInfo();
	const { database } = useFirebaseInit();
	const { fetchActivity, isLoaded: activityLoaded } = useSessionDetails();
	const { fetchDeviceInfo, isLoaded: sessionDeviceLoaded } =
		useSessionDetails();
	const { fetchKyc, isLoaded: sessionKycLoaded } = useSessionDetails();
	const { fetchAml, isLoaded: sessionAmlLoaded } = useSessionDetails();
	const { fetchKyb, isLoaded: sessionKybLoaded } = useSessionDetails();
	const { fetchSignAgreement, isLoaded: sessionSignLoaded } =
		useSessionDetails();
	const { fetchProofReading, isLoaded: sessionProofReadingLoaded } =
		useSessionDetails();
	const { fetchFundInvestment, isLoaded: sessionFundLoaded } =
		useSessionDetails();
	const { fetchAccreditation, isLoaded: sessionAccreditationLoaded } =
		useSessionDetails();
	const { fetchForm } = useSessionDetails();
	const { trackAttribute } = useMarketing();
	const { hasSubscribedBusiness } = useFreePlan();
	const { fetchingSessions: isSessionLoading } = useSessionsTextFilter();
	const [filteringTags, setFilteringTags] = useRecoilState(filteringTagsState);

	const { get: resendApi, loading: resendLoading } = useNetwork();
	const { get, update } = useIndexedDB(INIT_CONFIGURATION_SAVE);
	const { checkUserWritePermission } = useUserRoles();
	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Sessions),
		[checkUserWritePermission]
	);
	const isArchived = useMemo(
		() => sessionTypeState?.value === SessionTypesQuery?.Archived_Sessions,
		[sessionTypeState?.value]
	);
	const navigation = useNavigate();
	const cancelTokenSourceRef = useRef(null);

	const getSessionDetails = (id: string) => {
		fetchActivity(id);
		fetchDeviceInfo(id);
		fetchKyc(id);
		fetchAml(id);
		fetchKyb(id);
		fetchSignAgreement(id);
		fetchProofReading(id);
		fetchFundInvestment(id);
		fetchAccreditation(id);
		fetchForm(id);
	};

	// User data
	const { business = '', createdAt = '', invitedDate = '' } = useMemo(() => user ?? {}, [user]);

	const searchParams = useMemo(() => {
		const { search = '' } = location;
		const parmas = search?.split('&')[0]?.split('=')[1];

		return parmas;
	}, [location]);
	
	const currentActiveTab = useRef<any>();

	const { tillDate } = defineds ?? {};

	const businessStartDate = useMemo(() => {
		// Prioritize createdAt > invitedDate > current date
		const date = createdAt || invitedDate || Date.now();
		return new Date(date); // Ensure a valid Date instance
	  }, [createdAt, invitedDate]); // Recompute when dependencies change

	useEffect(() => {
		// Set the initial date range for the component.
		setSelectedDateRange([
			{
				startDate: businessStartDate, // The start date, derived from the `createdAt` value.
				endDate: tillDate, // The end date, representing the end of the current day.
				key: 'selection', // Identifier for this date range, useful for libraries like date pickers.
			},
		]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		currentActiveTab.current = filterActiveTab;
		restPageNumber()
	}, [filterActiveTab, restPageNumber]);

	// Build URL for API calling based on filter and search every time when filter and search change
	const buildUrl = useMemo(() => {
		const { endDate, startDate }: any = selectedDateRange[0] ?? {};
		const fromDate = getOffDate(startDate, 'start');
		const toDate = getOffDate(endDate, 'end');
		const filterUrl = parseUrl(filteringTags);

		let url = `${API_URL.COMPLEX_SESSION}?isDeleted=${isArchived}${
			filterTextSearch && '&textSearch=' + filterTextSearch
		}&pageNo=${
			filterTextSearch ? 1 : pageNumber
		}&pagination=${true}&pageSize=${pageLimitComplex}&isAssignedToMe=${false}&startDate=${fromDate}&endDate=${toDate}`;
		if (filterUrl && !searchParams) {
			url = `${API_URL.COMPLEX_SESSION}?isDeleted=${isArchived}${
				filterTextSearch && '&textSearch=' + filterTextSearch
			}&pageNo=${
				filterTextSearch ? 1 : pageNumber
			}&pagination=${true}&pageSize=${pageLimitComplex}&isAssignedToMe=${false}&startDate=${fromDate}&endDate=${toDate}&${filterUrl}`;
		}

		if (searchParams && !filterUrl) {
			url = `${API_URL.COMPLEX_SESSION}?isDeleted=${isArchived}${
				filterTextSearch && '&textSearch=' + filterTextSearch
			}&pageNo=${
				filterTextSearch ? 1 : pageNumber
			}&pagination=${true}&pageSize=${pageLimitComplex}&isAssignedToMe=${false}&startDate=${fromDate}&endDate=${toDate}&autoComplete=${searchParams}`;
		}

		if (searchParams && filterUrl) {
			url = `${API_URL.COMPLEX_SESSION}?isDeleted=${isArchived}${
				filterTextSearch && '&textSearch=' + filterTextSearch
			}&pageNo=${
				filterTextSearch ? 1 : pageNumber
			}&pagination=${true}&pageSize=${pageLimitComplex}&isAssignedToMe=${false}&startDate=${fromDate}&endDate=${toDate}&autoComplete=${searchParams}&${filterUrl}`;
		}
		return url;
	}, [
		selectedDateRange,
		filteringTags,
		isArchived,
		filterTextSearch,
		pageNumber,
		searchParams,
	]);

	// Seesion APIs calling after URL build
	const initComplexSessionData = useCallback(
		async ({ formPagination = false, bundleSessionId  }: { formPagination?: boolean, bundleSessionId?: string }) => {
			if (!formPagination && !bundleSessionId) setComplexListLoading(true);
			setIsPageLoading(true);

			// Cancel the previous request if it exists
			if (cancelTokenSourceRef.current && !bundleSessionId) {
				setCanceling(true);
				(cancelTokenSourceRef.current as any).cancel();
			}
			let newUrl = buildUrl; // Define newUrl outside the if/else block
			if (currentActiveTab.current) {
				if (currentActiveTab.current !== 'all') {
					newUrl += `&status[]=${currentActiveTab.current}`;
					if (currentActiveTab.current === 'assigned') {
						// Update `isAssignedToMe` to true
						newUrl = newUrl.replace(/&isAssignedToMe=false/, '&isAssignedToMe=true');
						// Remove `&status[]=assigned` part from the URL
						newUrl = newUrl.replace(/&status\[\]=assigned/, '');
					  }
				} else {
					newUrl = newUrl.replace(/&status\[\]=[^&]*/, '');
				}
			}
			if(bundleSessionId){
				newUrl = `${API_URL.COMPLEX_SESSION}?isDeleted=${isArchived}&parentSessionId=${bundleSessionId}`
			}else{
				setCollapseSessionOpenId([])
			}
			setRecallComplexSessionData(false);
			// Create a new CancelToken for the current request
			cancelTokenSourceRef.current = axios.CancelToken.source();
			const configHeader = {
				headers: {
					Authorization: `Bearer ${userDetails.accessToken}`,
				},
				cancelToken: (cancelTokenSourceRef.current as any).token,
			};
			const resp = await getComplexSessionList(newUrl, configHeader);
			if (resp?.message === 'ok') {
				setCanceling(false);
				setIsPageLoading(false);
				const { data } = resp;
				const { data: dataItems, total, ...rest } = data?.[0] ?? {};
				if(bundleSessionId){
					setComplexSessionList(prev => {
						const sessionList: any = structuredClone(prev);
						const bundleItemIndex = sessionList.findIndex((item: any) => item._id === bundleSessionId);
						const instances = (data ?? dataItems)?.map((item: any) => ({
							...item,
							type: 'complex',
							parentId: sessionList[bundleItemIndex]?._id,
						})) ?? [];
						sessionList[bundleItemIndex] = {...sessionList[bundleItemIndex], instances}
						return sessionList
					});
				}else{
					setComplexSessionList(dataItems ?? []);
					setPageCount(total);
					setTotalUnviewedCount(rest);
				}
			}
			setComplexListLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			filterActiveTab,
			currentActiveTab.current,
			userDetails.accessToken,
			getComplexSessionList,
			buildUrl,
			setComplexSessionList,
		]
	);

	// Fetch session after initComplexSessionData and buildUrl change

	useEffect(() => {
		initComplexSessionData({});
	}, [initComplexSessionData,recallComplexSessionData]);

	const initLinearData = async () => {
		const { endDate, startDate }: any = selectedDateRange[0] ?? {};
		const fromDate = getDate(startDate);
		const toDate = getDate(endDate);
		const resp = await getSessionList(
			`${API_URL.SESSIONS}?from=${fromDate}&to=${toDate}&isDeleted=${
				sessionTypeState.value === SessionTypesQuery.Archived_Sessions
			}${filterTextSearch && '&textSearch=' + filterTextSearch}`
		);

		if (resp?.message === 'ok') {
			const { data } = resp;
			setSessionList(data ?? []);
		}
	};

	// Listen firestore trigger events if any session perform write operations
	useEffect(() => {
		initLinearData();
		if (business && complexSessionList?.length) {
			const dataRef = ref(database, business);
			onValue(dataRef, snapshot => {
				const data = snapshot.val();
				if (data?.timeStamp === Memory.getTimeStamp()) {
					return;
				}
				Memory.setTimeStamp(data?.timeStamp);

				if (data?.business === business) {
					initComplexSessionData({});
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [business]);

	// reinit after filters change
	useEffect(() => {
		restPageNumber();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// eslint-disable-next-line react-hooks/exhaustive-deps
		selectedDateRange[0],
		sessionTypeState,
		filterTextSearch,
		filteringTags,
		searchParams,
	]);

	// Calling APIs continusly 4s
	useInterval(() => {
		if (sessionListLinearData?.data?.length === sessionList?.length) return;
		//    Prevent API calling if user was created after 02/10/2023 date
		if (createdAt) {
			const isAllowForCall = checkBefore2Oct23Date(createdAt);
			if (!isAllowForCall) initLinearData();
		}
	}, 5000);

	const handleInputSearch = useCallback(
		(value: string) => {
			navigation({
				pathname: ROUTES.SESSIONS,
				search: `q=${value}`,
			});
		},
		[navigation]
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounceInputSearch = useCallback(debounce(handleInputSearch, 500), []);

	const combinedSession = useMemo(() => {
		const linearSession = sessionList.map(item => ({
			...item,
			type: 'linear',
		}));

		const complexSession = complexSessionList.map(item => ({
			...item,
			type: 'complex',
		}));

		const upperDate = complexSession[0]?.date;
		const bottomDate = complexSession[complexSession.length - 1]?.date;

		const linearSessionToConcatenate = linearSession.filter(
			item =>
				new Date(item.date || 0).getTime() >=
					new Date(bottomDate || 0).getTime() &&
				new Date(item.date || 0).getTime() <= new Date(upperDate || 0).getTime()
		);

		const combined = linearSessionToConcatenate.concat(complexSession);

		const finalSession = searchParams
			? [...combined]
			: combined.sort((a, b) => {
					return (
						new Date(b.date || 0).getTime() - new Date(a.date || 0).getTime()
					);
			  });

		//Gaurav: When linear and Complex api response success then showed the final list.
		if (!complexListLoading) {
			update(finalSession ?? [], 'session');
			return finalSession;
		} else {
			return [];
		}
	}, [
		sessionList,
		complexSessionList,
		searchParams,
		complexListLoading,
		update,
	]);

	const isSessionsLoaded = useMemo(() => {
		if (!isPageLoading) {
			return true;
		}
		if (!!sessionListRows.length && !isPageLoading) {
			return true;
		}
		if (complexSessionListLoaded && sessionListLoaded) {
			return true;
		}
		return false;
	}, [
		sessionListRows.length,
		complexSessionListLoaded,
		sessionListLoaded,
		isPageLoading,
	]);

	const handleView = useCallback(
		async (item: any) => {
			setIsComplexTab(item.type === 'complex' ? true : false);
			if (item.type === 'linear') {
				setOpenView(true);
				getSessionDetails(item._id);
			} else {
				let sessionlist = []
				if(item?.checkbox){
					sessionlist = complexSessionList;
				}else{
					sessionlist = complexSessionList.find(el => el?._id === item?.parentId)?.instances ?? [];
				}
				const sessiondesign = sessionlist.find(
					(val: any) => val?._id == item?._id
				);
				setComplexSelectedSession(sessiondesign);
				setComplexSessionNodes(sessiondesign?.nodes ?? []);
				setComplexOpenView(true);
				complexSessionInfo(item._id , initComplexSessionData);
			}
			setSessionItemSelected(item);
			setSelectedIdState([item._id]);
			patch(`${API_URL.SESSION_DETAIL}/${item._id}`, { checkUser: true });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isComplexTab, getSessionDetails, complexSessionList, complexSessionInfo]
	);

	const viewComplexStep = useCallback((e: any, item: any) => {
		e.stopPropagation();
		setComplexStepView(item);
	}, []);

	const complexStepCloseModal = useCallback(() => {
		setComplexStepView(null);
	}, []);

	const reInviteSingle = useCallback(
		(
			e: React.MouseEvent<HTMLDivElement, MouseEvent>,
			fullStatus: { [key: string]: string },
			key: string,
			code: string,
			id: string,
			email: string,
			name: string
		) => {
			e.stopPropagation();
			if (!isUserPermissionWrite) return;
			if (
				(fullStatus[key] === 'pending' || fullStatus[key] === 'rejected') &&
				key === 'kyc'
			) {
				const activeStep = { step: key, code };
				setReinviteActiveStep(activeStep);
				setKycReinviteModal(true);
			} else {
				setOpenView(true);
				setSessionItemSelected({
					_id: id,
					sendEmail: email,
					name,
				});
				getSessionDetails(id);
				patch(`${API_URL.SESSION_DETAIL}/${id}`, { checkUser: true });
			}
			setSelectedIdState([id]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleCheckStepDisabled = useCallback(
		(fullStatus: { [key: string]: string }, key: string) => {
			if (
				actionKeyLabel[key] === 'KYC' &&
				(fullStatus[key] === 'pending' || fullStatus[key] === 'rejected')
			)
				return false;
			return true;
		},
		[]
	);

	const StatusJsx = useCallback(
		(
			fullStatus: { [key: string]: string },
			code: string,
			id: string,
			email: string,
			name: string
		) => (
			<>
				{fullStatus &&
					Object.keys(fullStatus).map(key => {
						return (
							<Tippy
								disabled={
									handleCheckStepDisabled(fullStatus, key) ||
									!isUserPermissionWrite
								}
								content={
									actionKeyLabel[key] === 'KYC' && 'Click here to start KYC'
								}
								key={key}
							>
								<div
									className={`status__clip statusbg__${fullStatus[
										key
									]?.toLocaleLowerCase()} statustct__${key}`}
									key={`session-list-${key}`}
									onClick={e =>
										reInviteSingle(e, fullStatus, key, code, id, email, name)
									}
								>
									<svg
										className="leftImg"
										width="6"
										viewBox="0 0 6 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M0 0H6V9.16667V20H0L6 9.16667L0 0Z" />
									</svg>

									<div className="inner__status">{actionKeyLabel[key]}</div>
									<svg
										width="6"
										viewBox="0 0 6 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M0 0L6 9.16667L0 20V0Z" />
									</svg>
								</div>
							</Tippy>
						);
					})}
			</>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleCloseViewModal = useCallback(() => {
		setOpenView(false);
		setIsComplexTab(false);
		setTimeout(() => {
			setSelectedIdState([]);
			setSessionItemSelected({});
			resetSessionSelected();
		}, 400);
		resetCcEmails();
		resetBCcEmails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleShowEsignLinkModal = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: {_id: string}) => {
		event.stopPropagation();
		setSelectedLinkSession(item)
		setOpenLinkEsignModal(true);
	},[setSelectedLinkSession])

	const handleSendSynergy = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.stopPropagation();
		setOpenSendSynergyModal(true);
	},[])

	const handleComplexCloseViewModal = useCallback(() => {
		resetSessionAssignee();
		setDropDownSelectedReviewer([]);
		resetComplexSelectedSessionAssigneeID();
		resetAssigneeSessionLoading();
		setSelectedSession([]);
		setSelectedIdState([]);
		setComplexOpenView(false);
		resetCcEmails();
		resetBCcEmails();
		resetComplexSessionInfo();
		setIsComplexTab(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleArchive = useCallback(
		(event: any, item: any) => {
			event.stopPropagation();
			setSelectedSession([item]);
			setSelectedIdState([item._id]);
			setSelectedIdsForZip([item._id]);
			setCheckBoxState(false);
			setSelectedSessionId(item._id);
			setIsArchiveModal(true);
		},
		[
			setCheckBoxState,
			setSelectedIdState,
			setSelectedIdsForZip,
			setSelectedSession,
		]
	);

	const DownloadPdf = useMemo(() => {
		return filterTableId.length > 0 ? (
			<>
				{/* this is disabled for now, may be enabled in future */}
				{sessionList.length || complexSessionList?.length ? (
					<ExportPdf
						sessionIDs={selectedIdsForZip}
						type={'zip'}
						label={'Download Zip'}
					/>
				) : (
					<></>
				)}
			</>
		) : (
			<></>
		);
	}, [
		complexSessionList?.length,
		filterTableId.length,
		selectedIdsForZip,
		sessionList.length,
	]);

	const handleDownloadPdf = useMemo(() => {
		return filterTableId.length > 0 ? (
			<>
				{/* this is disabled for now, may be enabled in future */}
				{sessionList.length > 0 && (
					<>
						{/* <ExportPdf
                sessionIDs={filterTableId}
                type={"pdf"}
                label={"Download Pdf"}
              /> */}
						<ExportPdf
							sessionIDs={selectedIdState}
							type={'zip'}
							label={'Download Zip'}
						/>
					</>
				)}
			</>
		) : null;
	}, [filterTableId.length, selectedIdState, sessionList.length]);

	const archiveButtonLabel = useMemo(() => {
		return !archiveLoading ? (
			sessionTypeState.value === SessionTypesQuery.Archived_Sessions ? (
				'Unarchive'
			) : (
				'Archive'
			)
		) : (
			<Loader className="loader-blue" dimension={26} />
		);
	}, [archiveLoading, sessionTypeState.value]);

	const handleUserDeleteModel = useCallback(
		async (isOpen: boolean, value: boolean) => {
			if (value) {
				const { type } = selectedSession?.[0] ?? {};
				const url =
					type === 'complex'
						? `${API_URL.COMPLEX_SESSION}/${selectedSessionId}`
						: `${API_URL.ARCHIVE_SESSION}/${selectedSessionId}`;
				const resp = await archiveSession(url);
				if (resp?.success || resp?._id) {
					if (type === 'complex') {
						setComplexSessionList(prev => {
							const prevObj = structuredClone(prev);
							const findIndex = prevObj.findIndex(
								el => el._id === selectedSessionId
							);
							prevObj.splice(findIndex, 1);
							return prevObj;
						});
					} else {
						setSessionListRows(prev => {
							const prevObj = JSON.parse(JSON.stringify(prev));
							const filterData = prevObj.filter(
								(el: any) => el?._id !== selectedSessionId
							);
							return filterData;
						});
					}
					successNotification(
						sessionTypeState.value === SessionTypesQuery.Archived_Sessions
							? message.UN_ARCHIVE_SESSION
							: message.ARCHIVE_SESSION
					);
				} else {
					errorNotification(resp?.message);
				}
				setIsArchiveModal(false);
				setSessionTypeState({
					label: 'Active',
					value: 'active_session',
				});
			} else {
				setIsArchiveModal(isOpen);
			}
			setSelectedSession([]);
			setSelectedIdState([]);
			setSelectedIdsForZip([]);
			setFilteringTags([]);
		},
		[
			setSelectedSession,
			setSelectedIdState,
			setSelectedIdsForZip,
			setFilteringTags,
			selectedSession,
			selectedSessionId,
			archiveSession,
			successNotification,
			sessionTypeState.value,
			setComplexSessionList,
			setSessionListRows,
			errorNotification,
			setSessionTypeState,
		]
	);

	const handleResend = useCallback(
		(
			e: React.MouseEvent<HTMLDivElement, MouseEvent>,
			code: string,
			isInviteEnable: boolean,
			type: string,
			isAllActionCompleted: boolean
		) => {
			e.stopPropagation();
			if (isInviteEnable) {
				return;
			}

			if (!isAllActionCompleted) {
				return;
			}

			setSelectedReInvite(code);
			//Deepak: call only if any of step in session is not completed
			// this is for type in complextab//
			let url = '';
			if (type !== 'complex') {
				url = `${API_URL.RESEND_INVITE}?code=${code}&resend=true`;
			} else {
				url = `${API_URL.RESEND_INVITE}?code=${code}&resend=true&type=complex`;
			}
			resendApi(url).then((res: any) => {
				if (res?.data?.length > 0) {
					successNotification('Invitation Sent');
				} else {
					errorNotification(MESSAGE.ERROR);
				}
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isComplexTab]
	);

	const handleCloseReinvite = useCallback(() => {
		setKycReinviteModal(false);
	}, []);

	const handleAssignSelectedSession = useCallback(
		(e: { stopPropagation: () => void }, session: any) => {
			e.stopPropagation();
			setSelectedIdState(prev => {
				const index = prev.findIndex((el: any) => el === session._id);
				if (index === -1) {
					return [...prev, session._id];
				} else {
					const newArr = [...prev];
					newArr.splice(index, 1);
					return newArr;
				}
			});
			setSelectedSession((prev: any) => {
				const index = prev.findIndex((el: any) => el._id === session._id);
				if (index === -1) {
					return [...prev, session];
				} else {
					const newArr = [...prev];
					newArr.splice(index, 1);
					return newArr;
				}
			});
			setSelectedIdsForZip(prev => {
				const index = prev.findIndex((el: any) => el === session._id);
				if (index === -1) {
					return [...prev, session._id];
				} else {
					const newArr = [...prev];
					newArr.splice(index, 1);
					return newArr;
				}
			});
		},
		[setSelectedIdState, setSelectedSession, setSelectedIdsForZip]
	);

	const handleConfigerBtn = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
			e.stopPropagation();
			setSelectedSession([]);
			setSelectedSessionId(id);
			setOpenConfigureSession(true);
		},
		[setSelectedSession]
	);

	const handleInviteEnable = (session: ICredential) => {
		const isInviteEnable =
			Object.keys(session.fullStatus ?? {}).filter(key => {
				if (session?.configuration?.['authentication'] === false) {
					return (
						key !== 'authentication' &&
						(session.fullStatus[key] === 'pending' ||
							session.fullStatus[key] === 'rejected' ||
							session.fullStatus[key] === 'failed')
					);
				} else {
					return (
						session.fullStatus[key] === 'pending' ||
						session.fullStatus[key] === 'rejected' ||
						session.fullStatus[key] === 'failed'
					);
				}
			}).length === 0;
		return isInviteEnable;
	};

	const sessionColumns = useMemo(() => {
		if (!isUserPermissionWrite) {
			return SessionTable.HEADER.filter(
				headerItem => headerItem.key !== 'action'
			);
		}
		return SessionTable.HEADER;
	}, [isUserPermissionWrite]);

	const sessionChildColumns = useMemo(() => {
		return sessionColumns.filter(el => el.key !== 'checkbox');
	}, [sessionColumns]);

	const initIndexDBData = useCallback(async () => {
		const resp: ICredential[] = await get('session');
		if (resp?.length > 0) {
			setSessionList(resp ?? []);
		}
	}, [get, setSessionList]);

	useEffect(() => {
		const rows: IShowFunds[] = [];
		funds.forEach((fund: any) => {
			if (fund) {
				let row: any = {};
				fundsColumn.forEach(({ key, format, key2 }: any) => {
					row.id = fund.id;
					if (key === 'totalMoneyRaised' || key === 'postMoneyValuation') {
						row = {
							...row,
							[key]: `${fund[key] ?? 0}`,
						};
					} else {
						row = {
							...row,
							[key]: fund[key] ?? '--',
						};
					}
					if (format === 'jsx' && key === 'moneyRaised') {
						const value = () => (
							<div className="funds-multiline-column">
								{`$${numberDecimal(Number(fund[key]))}`}
								{key2 ? (
									<div className="subText">
										<span className="subText-line" />
										<span>{fund[key2]}</span>
									</div>
								) : (
									''
								)}
							</div>
						);

						row = {
							...row,
							[key]: value,
							raised: fund[key] ?? '--',
							round: fund[key2] ?? '--',
						};
					}
				});
				rows.push(row);
			}
		});
		return setFundsRow(rows);
		// eslint-disable-next-line
	}, [funds]);

	const handleCloseIframe = useCallback(async () => {
		setShowSignModal(false);
		setSignPrepareUrl({ env: '', url: '' });
	}, []);

	const handleSubmitModal = useCallback(() => {
		handleCloseIframe();
		setTimeout(() => {
			successNotification('Document Signed Successfully.');
			getSignedDocsInbox(false);
		}, 1000);
	}, [getSignedDocsInbox, handleCloseIframe, successNotification]);

	const handleSignDoc = useCallback(
		async (
			e: React.MouseEvent<HTMLDivElement>,
			session: ICredential,
			isSignEnable: any
		) => {
			e.stopPropagation();
			if(isSignEnable?.action?.status === 'completed')return;
			setSignLoader(session?.code);
			const response = await signDocInbox(
				{
					envelopeId: isSignEnable?.envelopeId ?? '',
					recipientEmail: userDetails.email,
				},
				setSignLoader
			);
			const { url, env } = response ?? {};
			if (url && env) {
				setSignPrepareUrl({ url, env });
				setShowSignModal(true);
			}
		},
		[signDocInbox, userDetails]
	);

	const handleSignEnable = useCallback((session: ICredential) => {
		return session.nodes?.find(el => el.stepId === 'signAgreement' && !!el?.envelopeId);
	}, []);

	const checkSessionCompleted = useCallback((session: any) => {
		if(session?.status !== "completed" ){
			return false;
		}
		const filterdNodes = session.nodes.filter((el: any) => el.visited && el.stepId !== 'authentication' && el.stepId !== 'signAgreement');
		return filterdNodes.every((el: any) => el.action.status === 'completed')
	}, [])

	const handlCollapsibleId = useCallback((e: any ,item: any) => {
		e.stopPropagation();
		let list: string[] = [];
		setCollapseSessionOpenId((prev: string[]) => {list = [...prev]; return prev})
		if (list.indexOf(item._id) >= 0) {
			setCollapseSessionOpenId((prev: any) => [
				...prev.filter((x: any) => x !== item._id),
			]);
		} else {
			setCollapseSessionOpenId((prev: any) => [...prev, item._id]);
			initComplexSessionData({bundleSessionId: item._id})
		}
	},[initComplexSessionData]);

	const handleFormateSession = useCallback((sessionList: any, header = SessionTable.HEADER) => {
		const rows: IOptionsRow[] = [];
		const isNotChildSessions = header.find(el => el.key === 'checkbox');
		sessionList.forEach((session: any) => {
				const isInviteEnable = handleInviteEnable(session);
				const isSignEnable = handleSignEnable(session);
				const isSessionCompleted = checkSessionCompleted(session)
				if (session) {
					let row: any = {};
					const { type } = session ?? {};

					//shahbaaz logic for disable re-invite button
					let isAllActionCompleted = false;
					if (type === 'complex') {
						const allCompletedAction = session?.nodes?.filter(
							(el: { action: { status: string } }) =>
								!['completed', 'processing'].includes(el?.action?.status)
						);
						isAllActionCompleted = allCompletedAction?.length > 0;
					} else {
						isAllActionCompleted =
							session.status.toUpperCase() !== SessionStepStatus.COMPLETED;
					}

					header.forEach(({ format, key,key2,nameKey,emailKey,contactKey }) => {
						row._id = session._id;
						row.createdAt = session?.date;
						row.sendEmail = session.email;
						row.type = session.type;
						row.design = session.design;
						row.nodes = session.nodes;
						row.fund_name = session?.fundName;
						row.name = session?.name;
						row.instances = session?.instances?.length ? handleFormateSession(session?.instances, sessionChildColumns) : [];
						row.parentId = session?.parentId;
						row.linkedEnvelopes = session?.linkedEnvelopes;

						if (format === 'jsx' && key === 'checkbox') {
							const value = () => (
								<div
									onClick={e => handleAssignSelectedSession(e, session)}
									className="sessions-row-checkbox"
								>
									{/* pradeep : removed complex condition for download zip */}
									<input
										type="checkbox"
										className="session-checkbox"
										onChange={() => null}
										checked={
											!!selectedSession?.find(
												(item: any) => item._id === session._id
											)
										}
									/>
								</div>
							);
							return (row[key] = value);
						}
						if (format === 'jsx' && key === 'action') {
							const value = () =>
								!isUserPermissionWrite ? (
									<></>
								) : (
									<>
										{sessionTypeState.value !==
										SessionTypesQuery.Archived_Sessions ? (
											<div
												//@shivangi enable reinivite button for Complex flow//
												className={`btn-group ${
													!isAllActionCompleted
														? 'SessionTable-invite-disable'
														: ''
												} `}
												key={key}
												onClick={e =>
													handleResend(
														e,
														session.code,
														isInviteEnable ? false : isComplexTab,
														session.type,
														isAllActionCompleted
													)
												}
											>
												{!!isSignEnable &&
													isSessionCompleted &&
													business === REACT_APP_ARQ_SECURITIES_BUSINESSID &&
													(isSignEnable?.action?.status === 'processing' ||
														isSignEnable?.action?.status === 'completed') && (
														<div
															className={isSignEnable?.action?.status === 'completed'?'SessionTable-invite--sign-btn sign-btn-disabled':"SessionTable-invite--sign-btn"}
															onClick={e => handleSignDoc(e, session, isSignEnable)}
														>
															{signLoader === session.code ? (
																<div className="loader-wrapper">
																	<Loader
																		className="loader-white"
																		dimension={20}
																		type="loader"
																	/>
																</div>
															) : isSignEnable?.action?.status ===
															  'completed' ? (
																'Signed'
															) : (
																'Sign/View'
															)}
														</div>
													)}
												<div className="SessionTable-invite--Review">
													{resendLoading &&
													selectedReInvite === session.code ? (
														<div className="resend-loader">
															<Loader dimension={30} />
														</div>
													) : (
														'Re-invite'
													)}
												</div>
												{!!isNotChildSessions && <DropOptions
													item={row}
													handleAssignReviewer={()=>setOpenAssignReviewer(true)}
													handleAssignFund={() => setOpenSelectedFund(true)}
													handleLinkEsign={handleShowEsignLinkModal}
													handleArchiveDrop={handleArchive}
													DownloadPdf={() => handleDownloadPdf}
													handleConfigFund={handleConfigerBtn}
													handleSendSynergy={handleSendSynergy}
													
												/>}
												{/* need to add position abosolute and relative for below code
                      and wrap in div
                      <IndividualAction /> */}
											</div>
										) : (
											<Tippy content={'Click here to Unarchive'}>
												<div className="archive-btn">
													<IconBtn
														className="extra"
														btnClassName="ri-inbox-unarchive-line"
														handleClickIcon={(e: void) =>
															handleArchive(e, {
																_id: session._id,
																type: session.type,
															})
														}
													/>
												</div>
											</Tippy>
										)}
									</>
								);
							return (row[key] = value);
						}

						if (format === 'jsx' && key === 'fullStatus') {
							if (session.type === 'complex') {
								const value = () => (
									// <OnboardingTreeGraph
									//  details={session}
									//  showOnBoardingName
									//  viewComplexModal={(e: any) =>
									//      viewComplexStep(e, session.design)
									//  }
									// />
									<SessionTreeGraph
										details={session}
										showOnBoardingName
										isSessionList
										viewComplexModal={(e: any) => viewComplexStep(e, session)}
										maxNodes={7}
									/>
								);
								return (row[key] = value);
							} else {
								const value = () => (
									<>
										{' '}
										<div
											className={
												sessionTypeState.value ===
												SessionTypesQuery.Archived_Sessions
													? 'disable-in-archived session-status'
													: 'session-status'
											}
											key={key}
										>
											{StatusJsx(
												session[key],
												session.code,
												session._id,
												session.email,
												session.name
											)}
										</div>
										{key2 ? <div className="subTd">{session[key2]}</div> : ''}
									</>
								);
								return (row[key] = value);
							}
						}

						if (format === 'jsx' && key === 'nodes') {
							const value = () => (
								<OnboardingTreeGraph details={session} showOnBoardingName />
							);
							return (row[key] = value);
						}
						if (key === 'fundName') {
							if (!session.fundName) {
								return (row[key] = () => '--');
							}
							if (type === 'complex') {
								const fundList = session?.fundName?.split('|');
								const value = () => (
									<>
										<div className="text-ellipsis">{fundList?.[0] || '--'}</div>
										{session?.warning?.am === true && (
											<div className="aml-warning-message">
												<i className="ri-alarm-warning-fill"></i>
												Business & OFAC sanction alert
											</div>
										)}
									</>
								);
								return (row[key] = value);
							}
							if (type === 'linear') {
								return (row[key] = () => session.fundName);
							}
						}
						if (key === 'date') {
							const value = () => {
								return (
									<div>
										{/* Fund Name */}
										{isNotChildSessions && <div className="fund-name">
											{session.fundName ? (
												<>
													<div className="text-ellipsis">
														{session.fundName.split('|')[0] || '--'}
													</div>
													{session?.warning?.am === true && (
														<div className="aml-warning-message">
															<i className="ri-alarm-warning-fill"></i>
															Business & OFAC sanction alert
														</div>
													)}
												</>
											) : (
												'--'
											)}
										</div>}

										{/* Name */}
										<div className="onboarding-data">
											{session[nameKey as string] ? (
												<div>{session[nameKey as string]}</div>
											) : (
												'--'
											)}
										</div>
										{/* Email */}
										<div className="onboarding-data">
											{session[emailKey as string] ? (
												<div>{session[emailKey as string]}</div>
											) : (
												'--'
											)}
										</div>

										{/* Contact */}
										<div className="onboarding-data">
											{session[contactKey as string] ? (
												<div>{session[contactKey as string]}</div>
											) : (
												'--'
											)}
										</div>

										{/* Date */}
										<div className="onboarding-data">
											{session[key as string] ? (
												<>
													<div>
														{getTime(session[key as string]) +
															' | ' +
															getDate(session[key as string])}
													</div>
												</>
											) : (
												'--'
											)}
										</div>
										{/* Multi Session Bundle */}
										{REACT_APP_PSB_BUSINESSID === user?.business &&<div className="onboarding-data">
											{session?.isBundle && (
												<Button
													handleClick={(e)=>handlCollapsibleId(e, session)}
													label={
														<>
															<span>Multi Session Bundle</span>
															<i className="ri-arrow-down-s-line multi-btn-icon"></i>
														</>
													}
													type='onboarding-data__multi-btn'
												/>
											)}
										</div>}
									</div>
								);
							};

							row = {
								...row,
								[key]: value,
								sessionEmail: session[emailKey as string] ?? '--',
								sessionMobile: session[contactKey as string] ?? '--',
							};

							return (row[key] = value);
						}
						if (format === 'jsx') {
							const value = () => (
								<>
									{key === 'email' ? (
										<Tippy content={session[key]?.toLowerCase()}>
											<div className="text-ellipsis">{session[key]}</div>
										</Tippy>
									) : (
										<div className="text-ellipsis">{session[key]}</div>
									)}
									{key2 ? <div className="subTd">{session[key2]}</div> : ''}
								</>
							);

							row = {
								...row,
								[key]: value,
								sessionEmail: session[key] ?? '--',
								sessionMobile: session[key2 as string] ?? '--',
							};
							return (row[key] = value);
						}

						row = { ...row, [key]: session[key] ?? '--' };
						return null;
					});

					row = {
						...row,
						...session?.fullStatus,
						onboardingFlowName: session?.onBoardingName,
					};
					rows.push(row);
				}
			});
			return rows;
	},[
		StatusJsx, 
		business, 
		checkSessionCompleted, 
		handlCollapsibleId, 
		handleArchive, 
		handleAssignSelectedSession, 
		handleConfigerBtn,
		handleDownloadPdf, 
		handleResend, 
		handleShowEsignLinkModal, 
		handleSendSynergy,
		handleSignDoc, 
		handleSignEnable, 
		isComplexTab, 
		isUserPermissionWrite, 
		resendLoading, 
		selectedReInvite, 
		selectedSession, 
		sessionChildColumns, 
		sessionTypeState.value, 
		signLoader, 
		user?.business, 
		viewComplexStep
	]);

	useEffect(() => {
		if (combinedSession && SessionTable.HEADER.length) {
			const rows = handleFormateSession(combinedSession);
			return setSessionListRows(rows);
		}
		// eslint-disable-next-line
	}, [
		filterActiveTab,
		sessionList,
		signLoader,
		setSessionListRows,
		selectedSession,
		resendLoading,
		selectedReInvite,
		isComplexTab,
		combinedSession,
	]);

	useEffect(() => {
		initIndexDBData();
	}, [initIndexDBData]);

	// Shahbaaz: Tracking last five session for marketing
	useEffect(() => {
		trackAttribute({
			invite_sent: combinedSession?.length ?? 0,
		});
		if (combinedSession?.length > 0 && !hasSubscribedBusiness) {
			const firstFive = combinedSession?.slice(-5);
			(firstFive ?? []).forEach(el => {
				trackAttribute({
					freeplan_first_at_onboarding: el.date ?? '',
					freeplan_second_at_onboarding: el.date ?? '',
					freeplan_third_at_onboarding: el.date ?? '',
					freeplan_fourth_at_onboarding: el.date ?? '',
					freeplan_last_at_onboarding: el.date ?? '',
				});
			});
			const firstInvite = combinedSession?.at(-1);
			if (firstInvite)
				trackAttribute({
					first_invite_sent_at_onboarding: firstInvite.date ?? '',
					first_flow_start_at_sign: firstInvite.date ?? '',
				});
			(firstFive ?? []).forEach(el => {
				const isInviteEnable = handleInviteEnable(el);
				if (isInviteEnable) {
					trackAttribute({
						first_flow_complete_at_onboarding: el?.updatedAt ?? '',
					});
				}
				return;
			});
		}
	}, [combinedSession, trackAttribute, hasSubscribedBusiness]);

	const isAssignFundDisabled = useMemo(() => {
		return !!selectedSession.find(
			(session: { type: string }) => session.type === 'complex'
		);
	}, [selectedSession]);

	const handleAssigneeOpen = useCallback(() => {
		if (!isUserPermissionWrite) return;
		if (!selectedSession.length) {
			toast.error('Please select at least one session.');
			return;
		}
		setOpenAssignReviewer(true);
	}, [isUserPermissionWrite, selectedSession.length]);

	const sessionFundPdfDownloadButton = useMemo(
		() => (
			<>
			{selectedSession?.length ? (
			<div className={`session-header-action-btns `}>
				
				<div className="session-right">
					<div className="session-right__first">
							<div className="arc-btn">
								{`${selectedSession?.length} Selected`}{' '}
								<span
									onClick={() => {
										setSelectedIdState([]);
										setSelectedIdsForZip([]);
										setSelectedSession([]);
										setCheckBoxState(false);
									}}
								>
									{selectedSession?.length > 0 && (
										<i className="ri-close-line" />
									)}
								</span>
							</div>
						<div className="filter-btn-wrapper zindex">
							<div className="reviewer-container">
								<div
									className={
										isUserPermissionWrite
											? 'assign-box-model user-permission__enable'
											: 'assign-box-model user-permission__disable'
									}
									onClick={() => handleAssigneeOpen()}
								>
									<p className="title">Assign Reviewer</p>
									<i className={`ri-arrow-down-s-line arrow`}></i>
								</div>
							</div>
						</div>
						{sessionTypeState.value !== SessionTypesQuery.Archived_Sessions &&
							isUserPermissionWrite && (
								<Button
									disabled={
										selectedSession?.length === 0 || isAssignFundDisabled
									}
									label="Assign Fund/Company"
									handleClick={() => setOpenSelectedFund(true)}
									type="assign-fund-btn"
								/>
							)}
					</div>
					{DownloadPdf}
				</div>
			
			</div>
		) : null}
		</>
		),
		[
			DownloadPdf,
			handleAssigneeOpen,
			isAssignFundDisabled,
			isUserPermissionWrite,
			selectedSession?.length,
			sessionTypeState.value,
			setCheckBoxState,
			setSelectedIdState,
			setSelectedIdsForZip,
			setSelectedSession,
		]
	);

	const renderChildSessions = useCallback(
		(rows: any) => {
			return (
				<div className='ChildSession__container'>
					<i className="ri-node-tree ChildSession__container-treeIcon"></i>
					<ReactResponsiveTable
						tableType="sessions"
						column={sessionChildColumns}
						rows={rows}
						columnHandle
						handelRowClick={session => handleView(session)}
						hideTableHeader
					/>
				</div>
			);
		},
		[handleView, sessionChildColumns]
	);
	const renderSessions = useMemo(() => {
		return (
			<ReactResponsiveTable
				tableType="sessions"
				downloadZip={DownloadPdf as any}
				isLoading={complexListLoading || isSessionLoading || canceling}
				isLoaded={isSessionsLoaded}
				column={sessionColumns}
				rows={isSessionLoading || canceling ? [] : sessionListRows}
				showSearch
				showFilter
				showArchiveFilter
				showAssignee={true}
				showDateFilter
				columnHandle
				height="calc(100vh - 140px) !important"
				emptyHeight={'calc(100vh - 255px)'}
				handelRowClick={session => handleView(session)}
				filterPage="sessions"
				EmptyIllustration="empty-sessions.svg"
				EmptyMessageHeading="No Session Detail Available"
				EmptyMessageDescription="You do not have any session yet."
				isFilterDisabled={isComplexTab}
				isArchiveDisabled={isComplexTab}
				isPagination
				totaloPageCount={pagecount}
				handleInputSearch={debounceInputSearch}
				searchParams={searchParams}
				downloadChildren={sessionFundPdfDownloadButton}
				showFilterTab={true}
				handleTabClick={() => initComplexSessionData({})}
				collapsible={true}
				collapseOpenId={collapseSessionOpenId}
				defaultComponent={renderChildSessions}
			/>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		DownloadPdf,
		complexListLoading,
		isSessionLoading,
		sessionColumns,
		sessionListRows,
		isComplexTab,
		handleView,
		pagecount,
		isSessionsLoaded,
		debounceInputSearch,
		searchParams,
		sessionFundPdfDownloadButton,
	]);

	return (
		<div className="dashboard-main-body">
			<Header title="Sessions" showBtn={false}>
				<ColorLegends title={'Status & Onboarding Flow Legends:'} />
			</Header>
			{/* Tabs */}
			<div className="session-page">
				{!complexOpenView ? renderSessions : null}
			</div>

			{openView && (
				<Modal
					isOpen={openView}
					closeModal={handleCloseViewModal}
					className="react-modal_kycDetail"
					modalName="Session modal"
				>
					<ViewKyc
						activityLoaded={activityLoaded}
						sessionAmlLoaded={sessionAmlLoaded}
						sessionDeviceLoaded={sessionDeviceLoaded}
						sessionAccreditationLoaded={sessionAccreditationLoaded}
						sessionSignLoaded={sessionSignLoaded}
						sessionKycLoaded={sessionKycLoaded}
						sessionKybLoaded={sessionKybLoaded}
						sessionFundLoaded={sessionFundLoaded}
						sessionProofReadingLoaded={sessionProofReadingLoaded}
					/>
				</Modal>
			)}

			{/* Complex Session view details */}
			{complexOpenView && (
				<Modal
					isOpen={complexOpenView}
					closeModal={handleComplexCloseViewModal}
					className="react-modal_kycDetail"
					modalName="Session modal"
				>
					<ComplexSessionView isLoading={complexSessionDetailsLoading} />
				</Modal>
			)}

			{kycReinviteModal && (
				<ReInviteKyc
					open={kycReinviteModal}
					handleClose={handleCloseReinvite}
					reinvitedStep={reinviteActiveStep}
				/>
			)}

			{openLinkEsignModal && (<SessionEsignLink isOpen={openLinkEsignModal} handleCloseModal={()=> setOpenLinkEsignModal(false)} selectedSession={selectedLinkSession} initComplexSessionData={initComplexSessionData}/>)}
			{openSendSynergyModal && (<SessionSendSynergy isOpen={openSendSynergyModal} handleCloseModal={() => setOpenSendSynergyModal(false)}/>)}
			{openSelectedFund && (
				<SelectedFundModal
					isOpen={openSelectedFund}
					handleClose={() => {
						setOpenSelectedFund(false);
						setSelectedSession([]);
						setCheckBoxState(false);
						setSelectedIdState([]);
						setSelectedIdsForZip([]);
					}}
				/>
			)}
			{openAssignReviewer && (
				<AssignReviewerModal
				isOpen={openAssignReviewer}
				handleClose={()=>{
					setOpenAssignReviewer(false)
					setSelectedSession([]);
				}}
				isCurrentUser={true}
				/>
			)}
			{openConfigureSession && (
				<ConfigureSession
					isOpen={openConfigureSession}
					selectedSessionId={selectedSessionId}
					handleClose={() => {
						setOpenConfigureSession(false);
						setSelectedSession([]);
						setCheckBoxState(false);
						setSelectedIdState([]);
						setSelectedIdsForZip([]);
					}}
				/>
			)}

			{isArchiveModal && (
				<ArchiveConfirmationModal
					visible={isArchiveModal}
					title={'Are you sure?'}
					handleModal={handleUserDeleteModel}
					description={
						sessionTypeState.value === SessionTypesQuery.Archived_Sessions
							? message.UN_ARCHIVE_DESCRIPTION
							: message.ARCHIVE_DESCRIPTION
					}
					label={archiveButtonLabel}
					showLoader={archiveLoading}
				/>
			)}

			{complexStepView?._id && (
				<ComplexStepsView
					isOpen={!!complexStepView?._id}
					closeModal={complexStepCloseModal}
					details={complexStepView}
				/>
			)}

			{showSignModal && (
				<InboxSignModal
					modalVisibility={showSignModal}
					handleCloseIframe={handleCloseIframe}
					prepareUrl={signPrepareUrl}
					handleSubmitModal={handleSubmitModal}
				/>
			)}
		</div>
	);
};

export default Sessions;
