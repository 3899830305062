import type { IDocuments } from 'views/signed-doc-inbox';

import { createRef, FC, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
	Button,
	Image,
	Loader,
	ReactDropdown,
	ReactResponsiveTable,
} from '@storybook';
import Tippy from '@tippyjs/react';
import {
	AllConditionalPipelinesState,
	AllPipelinesState,
	FundsState,
	SelectedDocFilesForUploadState,
	SelectedDocsForUploadState,
	SelectedPipelineDetails,
	UploadedDocsState,
	useSignedDocs,
} from 'global-stores';
import { useAllowedInvites, useNotification, useOutsideClick } from 'hooks';
import { formatSizeUnits, getDate, getTime } from 'utils';
import { WelcomeNewUserModalState } from 'views/new-user-popup';
import {
	ComplexConfigurationInvite,
	IComplexConfig,
	IFundInvestment,
	ISignAgreement,
	IsQrInviteState,
} from 'views/pipelines/components/store';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { PipelineInstanseTable } from '../../constants';
import {
	IAgreements,
	IOnboardingQRModalNavigate,
	ISelectedPipelineQrState,
	OnboardingQRModalNavigateState,
	OnboardingQRModalOpenState,
	SelectedPipelineDetailsForQRState,
	SelectedPipelineQrData,
	SelectedPipelineQrListState,
	SelectedQRInstanceState,
	SelectedQrInstanceState,
	SelectedQrOnbordingFlowName,
	SelectedQRSate,
	SelectedQRTemplateIdState,
	useOnboardingQrFlow,
} from '../../stores';
import { DropOption } from '../dropOptions';
import { ErrorSessionsNotWrite } from '../../../../constants';

import './onboarding-qr-table.scss';
import Modal from '@storybook/new-modal/modal';
import { EventsState } from 'views/events/store';
import { IOption } from 'views/complex-onboarding-flow/components/dropdown';
import { EVENTURL } from 'views/events';
// import { envHost } from 'helpers';

interface IOnboardingQRTable {
	isLoadingQrList: boolean;
	setQrLoadingState?: () => void;
	piplineData?: any;
	selectedPipelineQr?: any;
	combinedOnboarding?: any;
}

export const OnboardingQRTable: FC<IOnboardingQRTable> = ({
	isLoadingQrList,
	piplineData,
	selectedPipelineQr,
	combinedOnboarding,
}) => {
	// globle states
	const [pipelineDetails, setPipelineDetailsForQR] = useRecoilState(
		SelectedPipelineDetailsForQRState
	);
	const selectedPipelineQrList = useRecoilValue(SelectedPipelineQrListState);
	const setNavigate = useSetRecoilState(OnboardingQRModalNavigateState);
	const setQRTemplateId = useSetRecoilState(SelectedQRTemplateIdState);
	const setQRInstanceState = useSetRecoilState(SelectedQRInstanceState);
	const setQrInstance = useSetRecoilState(SelectedQrInstanceState);
	const setQrOnbordingFlowName = useSetRecoilState(SelectedQrOnbordingFlowName);
	const setIsOnboaringQrModal = useSetRecoilState(OnboardingQRModalOpenState);
	const [selectedQRSate, setSelectedQRSate] = useRecoilState(SelectedQRSate);
	const setQRflowData = useSetRecoilState(SelectedPipelineQrData);
	const setPipelineDetails = useSetRecoilState(SelectedPipelineDetails);

	const conditionalPipelines = useRecoilValue(AllConditionalPipelinesState);
	const pipelineData = useRecoilValue(AllPipelinesState);
	const setWelcomeNewUser = useSetRecoilState(WelcomeNewUserModalState);
	const funds = useRecoilValue(FundsState);
	// Pradeep chaurasia : state for selected files
	const setSelectedFiles = useSetRecoilState(SelectedDocsForUploadState);
	const setSelectedDocFiles = useSetRecoilState(SelectedDocFilesForUploadState);

	const setIsQrFlow = useSetRecoilState(IsQrInviteState);

	const setAddedFiles = useSetRecoilState(UploadedDocsState);
	const setConfiguration = useSetRecoilState(ComplexConfigurationInvite);

	//local state
	const [showPopup, setShowPopup] = useState({
		id: '',
	});

	// Events
	const [isAssignEventModal, setAssignEventModal] = useState(false);
	const [isApiLoader, setApiLoader] = useState(false);

	const [eventId, setEventId] = useState('');

	const eventsState = useRecoilValue(EventsState);

	// hooks
	const { deleteQrInstance } = useOnboardingQrFlow();
	const { isAllowedToInvite } = useAllowedInvites();
	const { checkUserWritePermission } = useUserRoles();
	const { getDocument } = useSignedDocs();
	const { successNotification, errorNotification } = useNotification();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Onboarding),
		[checkUserWritePermission]
	);
	const isSessionWritePermission = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Sessions),
		[checkUserWritePermission]
	);

	//ref
	const ref = createRef<HTMLDivElement>();
	useOutsideClick(ref, () => {
		setShowPopup({ id: '' });
	});

	const selectedPipelineQRs = useMemo(
		() => (selectedPipelineQr ? selectedPipelineQr : selectedPipelineQrList),
		[selectedPipelineQr, selectedPipelineQrList]
	);
	const selectedPipelines = useMemo(
		() => (piplineData ? piplineData : pipelineDetails),
		[pipelineDetails, piplineData]
	);

	// const handleOpenAssignEvent = (item: any) => {
	// 	setSelectedQRSate(item._id);
	// 	setAssignEventModal(true);
	// };

	const onCloseEvent = () => {
		setAssignEventModal(false);
	};

	const handleChangeSelect = (item: IOption) => {
		setEventId(item.value);
	};

	const eventOption = useMemo(() => {
		return (
			eventsState.map(event => ({
				label: event.name,
				value: event._id,
			})) ?? []
		);
	}, [eventsState]);

	const onAssignEvent = async () => {
		const qrIds = eventsState?.find((event) => event._id === eventId)?.qrIds ?? []
		setApiLoader(true);
		const payload = {
			qrIds: [...qrIds,selectedQRSate],
		};

		try {
			const resp = await fetch(`${EVENTURL}/events/${eventId}?type=assign`, {
				method: 'PATCH',
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json',
				},
			});
			const data = await resp.json();
			if (data.message === 'ok') {
				successNotification('Event assigned successfully');
				onCloseEvent();
			}
		} catch (error) {
			errorNotification('Failed to assign event');
		} finally {
			setApiLoader(false);
		}

		return;
	};

	const getFundName = useCallback(
		(fundId: string) => {
			return (
				funds?.find(({ id, _id }) => id === fundId || _id === fundId)?.name ||
				'--'
			);
		},
		[funds]
	);

	// table columns
	const ColumnHeaders = useMemo(() => {
		const arr: any[] = [];
		if (PipelineInstanseTable?.HEADER) {
			PipelineInstanseTable?.HEADER.forEach(item => {
				if (
					item.key === 'payIn' &&
					!selectedPipelines?.fullStatusData?.includes('payIn')
				) {
					return;
				}
				if (
					item.key === 'signAgreement' &&
					!selectedPipelines?.fullStatusData?.includes('signAgreement')
				) {
					return;
				}
				if (item.key === '506b' && !selectedPipelineQRs?.[0]?.accreditation) {
					return;
				}
				arr.push(item);
			});
		}
		if (!isUserPermissionWrite) {
			return (arr ?? []).filter(headerItem => headerItem.key !== 'action');
		}
		return arr;
	}, [selectedPipelines, selectedPipelineQRs, isUserPermissionWrite]);

	const circledDocs = useCallback(
		(key: string) => (
			<div key={key} className="doc-wrapper">
				<Image fileName="PDF.svg" />
			</div>
		),
		[]
	);

	const renderSignDocs = useCallback(
		(item: ISelectedPipelineQrState, key: 'agreements') => (
			<>
				{item[key].map(
					({ _id }, index: number) => index < 3 && circledDocs(_id)
				)}
				{item[key]?.length > 3 && (
					<div
						onMouseLeave={() =>
							setShowPopup({
								id: '',
							})
						}
						onMouseEnter={() =>
							setShowPopup({
								id: !showPopup.id
									? item._id
									: showPopup.id === item._id
									? ''
									: item._id,
							})
						}
						className={`doc-wrapper docs-wrapper__see-more ${
							item._id === showPopup.id
								? 'doc-wrapper docs-wrapper__see-more--clicked'
								: ''
						}`}
					>
						+{item[key]?.length - 3}
						{item._id === showPopup.id && (
							<div
								ref={ref}
								onClick={e => e.stopPropagation()}
								className="docs-wrapper__see-more-popup"
							>
								{item[key].map(
									({ _id }, index: number) => index >= 3 && circledDocs(_id)
								)}
							</div>
						)}
					</div>
				)}
			</>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ref, showPopup.id]
	);

	const handleClickOnActions = useCallback(
		(
			navigateKey: IOnboardingQRModalNavigate,
			item: ISelectedPipelineQrState
		) => {
			setQrOnbordingFlowName(piplineData?.name ?? '');
			setQRflowData(item);
			setIsOnboaringQrModal(true);
			setQrInstance(item);
			setNavigate(navigateKey);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleInviteCSV = useCallback(
		(item: any) => {
			if (!selectedPipelines.isInviteAllowed || !isSessionWritePermission) {
				return;
			}
			if (!isAllowedToInvite('onboarding')) {
				setWelcomeNewUser({
					open: true,
					type: 'Credits_Remain',
					serviceName: 'Onboarding Service',
				});
				return;
			}
			const overlayData = item?.agreements?.find(
				(item: IAgreements) => item.templateType == 'overlay'
			);
			const filterData = (
				overlayData ? conditionalPipelines : pipelineData
			)?.find(subItem => subItem?._id === item?.pipelineId);
			setPipelineDetails(filterData);
			setPipelineDetailsForQR(filterData);
			setQRflowData(item);
			setSelectedQRSate(item._id);
			setNavigate('csv');
			if (overlayData) {
				setQRInstanceState(item?.agreements);
				setQRTemplateId(overlayData.templateId);
			}
			setIsOnboaringQrModal(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			isAllowedToInvite,
			pipelineData,
			conditionalPipelines,
			selectedPipelines.isInviteAllowed,
		]
	);

	const selectedArchiveQR = useCallback(
		(piplelineId: string) => {
			const selectedFlow = combinedOnboarding.filter(
				(item: any) => item?._id === piplelineId
			);
			setPipelineDetails(selectedFlow?.[0]);
			return selectedFlow?.[0];
		},
		[combinedOnboarding, setPipelineDetails]
	);

	/**
	 * Handles the reconfiguration of a QR code for onboarding.
	 * @param {Event} e - The event that triggered the reconfiguration.
	 * @param {Object} item - The item containing banks, agreements, and pipelineId for reconfiguration.
	 */
	const handleReConfiguredQR = useCallback(
		(e: any, item: IComplexConfig) => {
			e.stopPropagation();

			// Extract data from the item object
			const {
				banks = [],
				agreements = [],
				pipelineId = '',
				_id: qrID = '',
			} = item ?? {};
			setSelectedQRSate(qrID);
			// Find and set pipeline details for the QR
			const combinedOnboardingData = (conditionalPipelines ?? []).concat(
				pipelineData ?? []
			);
			const pipelineDetails = combinedOnboardingData.find(
				el => el._id === pipelineId
			);
			setPipelineDetailsForQR(pipelineDetails ?? {});
			// Select the archive QR and set navigation and modal states
			selectedArchiveQR(pipelineId);
			setNavigate('reconfigQR');
			setIsOnboaringQrModal(true);
			setIsQrFlow(true);
			// Transform banks into fundInvestment
			const fundInvestment = (banks ?? []).map((bank: IFundInvestment) => ({
				...bank,
				node: bank.nodeId,
			}));
			// Update configuration state with signAgreement and fundInvestment data
			setConfiguration({
				signAgreement: agreements,
				fundInvestment,
			});
			// Process each agreement and related documents
			(agreements ?? []).forEach(async (agreement: ISignAgreement) => {
				const documentData = await getDocument(agreement.templateId);
				const {
					name: templateName,
					documents = [],
					docRefId,
					createdAt,
					prepareUrl,
				} = documentData || {};
				let eSignTempsize = 0;

				documents.forEach((doc: IDocuments) => {
					eSignTempsize += Number(doc.document.size);
				});
				const name: string = documents[0]?.document?.name;

				// Create a payload object for the agreement
				const payload = {
					...agreement,
					name,
					templateName,
					createdAt,
					prepareUrl,
					configured: true,
					isChecked: true,
					size:
						formatSizeUnits(
							parseFloat(docRefId?.size ?? eSignTempsize ?? 0)
						).toString() ?? '-',
				};

				// Update addedFiles and selectedFiles arrays with the payload
				setAddedFiles(prev => [...prev, payload]);
				setSelectedFiles(prev => [...prev, payload]);
				setSelectedDocFiles(prev => [...prev, payload])
			});
		},
		[
			conditionalPipelines,
			pipelineData,
			selectedArchiveQR,
			setAddedFiles,
			setConfiguration,
			setIsOnboaringQrModal,
			setNavigate,
			setPipelineDetailsForQR,
			setSelectedFiles,
			setSelectedQRSate,
			setIsQrFlow,
			getDocument,
			setSelectedDocFiles,
		]
	);

	const handleDescription = useMemo(
		() => (
			<span className="actions-wrapper__description-light">
				Are you sure you want to archive this QR code instance?
			</span>
		),
		[]
	);

	// table rows
	const tableRow = useMemo(() => {
		const rows: any[] = [];
		if (selectedPipelineQRs) {
			let row = {};
			selectedPipelineQRs.forEach((item: any) => {
				const isArchived = item?.isDeleted;

				const { agreements } = item;
				const isBasicFlow = agreements.every(
					(agreement: any) => agreement.templateType === 'basic'
				);

				// TODO: added complex type
				const isComplex = !!item?.banks;
				(ColumnHeaders ?? []).forEach(({ key, format }) => {
					if (format === 'jsx' && key === '506b') {
						const value = () => (
							<>
								{item?.['accreditation']
									? circledDocs(item['accreditation'])
									: '--'}
							</>
						);

						row = { ...row, [key]: value };
						return;
					}
					if (format === 'jsx' && key === 'signAgreement') {
						const value = () => (
							<>
								<div className="docs-wrapper">
									{item?.agreements && item.agreements.length
										? renderSignDocs(item, 'agreements')
										: '--'}
								</div>
							</>
						);

						row = { ...row, [key]: value };
						return;
					}
					if (format === 'jsx' && key === 'date') {
						const value = () => (
							<div className="created-date">
								{item?.['createdAt'] ? getDate(item?.['createdAt']) : '--'}{' '}
								{item?.['createdAt'] ? (
									<span className="subId">{getTime(item?.['createdAt'])}</span>
								) : (
									''
								)}
							</div>
						);

						row = { ...row, [key]: value };
						return;
					}
					if (format === 'jsx' && key === 'action') {
						const value = () =>
							!isUserPermissionWrite ? (
								<></>
							) : (
								<div className="actions-wrapper">
									{!item.isDeleted ? (
										<>
											<Tippy
												content={
													!isSessionWritePermission ? (
														<div style={{ textAlign: 'center' }}>
															{ErrorSessionsNotWrite}
														</div>
													) : (
														''
													)
												}
												disabled={isSessionWritePermission}
											>
												<>
													<div
														className={`invite_column ${
															selectedPipelines?.className ?? ''
														} ${!isSessionWritePermission && 'disabled-btn'}`}
														onClick={() => handleInviteCSV(item)}
													>
														Invite
													</div>

													{/* {envHost !== 'prod' && (
														<div
															className="assign_column"
															onClick={() => handleOpenAssignEvent(item)}
														>
															Assign event
														</div>
													)} */}
												</>
											</Tippy>
												
													{!isArchived && isBasicFlow ? (
											<Tippy content={isArchived ? 'Archived item' : 'QR'}>
												<div className='actions-wrapper-btn'>
														<Button
															label={<i className="ri-qr-code-line actions-wrapper-btn-config" />}
															handleClick={() =>
																handleClickOnActions('qr_image', item)
															}
															type="menu-item actions-wrapper__hover"
															/></div>
															</Tippy>
													) : (
														<div style={{ width: 34 }} />
													)}
												
											<Tippy content={isArchived ? 'Archived item' : 'Embed'}>
											<div className='actions-wrapper-btn'>
											{!isArchived && isBasicFlow ? (
												<Button
													label={<i className="ri-code-s-slash-line actions-wrapper-btn-config" />}
													handleClick={() =>
														handleClickOnActions('embed_code', item)
													}
													type="menu-item actions-wrapper__hover"
												/>
											) : (
												<div style={{ width: 34 }} />
											)}
											</div>
											</Tippy>
											<DropOption
												description={handleDescription}
												handleDelete={() =>
													deleteQrInstance(item?._id, isComplex)
												}
											/>
										</>
									) : (
										piplineData.type === 'complex' && (
											<div
												className="invite_column"
												onClick={e => handleReConfiguredQR(e, item)}
											>
												Re-configure
											</div>
										)
									)}
								</div>
							);

						row = { ...row, [key]: value };
						return;
					}
					if (key === 'instanceName') {
						row = {
							...row,
							[key]: (
								<div
									style={{
										display: 'flex',
									}}
								>
									<div>
										{(item['name'].length > 17 ? (
											<Tippy placement="top-start" content={item['name']}>
												<div
													className="text-elipsis"
													style={{ paddingLeft: '0px' }}
												>
													{item['name']}
												</div>
											</Tippy>
										) : (
											item['name']
										)) ?? '--'}
									</div>
									{item.isDeleted && (
										<div className="archive-label">New changes added</div>
									)}
								</div>
							),
						};
					}
					if (key === 'payIn') {
						let fundValue =
							item?.fund?.name ?? getFundName(item?.fundId) ?? '--';
						if (isComplex) {
							fundValue = item?.banks.map((bank: any) => {
								return (
									<div key={bank?.accountId}>
										{bank?.fundName ?? getFundName(bank?.fundId) ?? '--'}
									</div>
								);
							});
						}
						row = { ...row, [key]: fundValue };
					}
				});
				rows.push(row);
			});
		}
		return rows.reverse();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		ColumnHeaders,
		circledDocs,
		deleteQrInstance,
		getFundName,
		handleClickOnActions,
		handleInviteCSV,
		handleReConfiguredQR,
		isUserPermissionWrite,
		piplineData.type,
		renderSignDocs,
		selectedPipelineQRs,
		selectedPipelines?.className,
		isSessionWritePermission,
		handleDescription,
	]);

	return (
		<div className="onboarding-flow-instance-wrapper">
			{isLoadingQrList ? (
				<div className="onboarding-flow-instance-wrapper__loader">
					<Loader type="gif" />
				</div>
			) : selectedPipelineQRs && selectedPipelineQRs.length ? (
				<ReactResponsiveTable
					isLoading={isLoadingQrList}
					tableType="pipeline_qr_list"
					column={ColumnHeaders}
					rows={tableRow}
					emptyHeight={'100%'}
					EmptyIllustration={'empty-api-list.svg'}
					EmptyMessageHeading={'No QR Instance Available'}
					EmptyMessageDescription={'You have not created any QR instance yet.'}
					headerZIndex={0}
				/>
			) : (
				<></>
			)}
			
			{/* Assign Event Modal*/}
			{isAssignEventModal && (
				<Modal
					isOpen={isAssignEventModal}
					modalName="Events"
					closeModal={onCloseEvent}
					className="Events-Modal"
					showCloseBtn
					isStopOutsideClick={false}
					optionalClassName="Events--Modal-body"
				>
					<div className="Events--header">
						<h2>Assign Events</h2>
					</div>
					<div className="Events--form">
						<ReactDropdown
							label="Events"
							options={eventOption}
							isSearchable
							placeholder="Select"
							handleChangeSelect={handleChangeSelect}
							isRequired
						/>
					</div>
					<div className="Events--footer">
						<Button
							label="Cancel"
							type="button__filled button__filled--secondary"
							handleClick={onCloseEvent}
						/>
						<Button
							label={
								!isApiLoader ? (
									'Assign Event'
								) : (
									<Loader dimension={20} type="loader" />
								)
							}
							type="button__filled button__filled--primary"
							handleClick={onAssignEvent}
							disabled={!eventId || isApiLoader}
						/>
					</div>
				</Modal>
			)}
		</div>
	);
};
